<template>
  <div id="parse">
    <!-- 头部容器 -->
    <div class="header-container">
      <!-- 返回按钮 -->
      <router-link class="chevron-left" to="/"><i class="fas fa-chevron-left"></i></router-link>
      <!-- 标题 -->
      <h1 class="title">解析结果</h1>
    </div>
    <!-- 内容容器 -->
    <div class="content-container">
      <!-- 加载状态 -->
      <el-skeleton class="search-tags-loading" v-if="loading" :loading="true" :rows="3" animated></el-skeleton>
      <!-- 解析结果列表 -->
      <div class="transfer-container" v-if="!loading && list.length !== 0">
        <!-- 吸附在左侧的悬浮图标 -->
        <div class="floating-icon" @click="toggleInfo">
          <i class="fas fa-info"></i>
        </div>
        <!-- IP地址信息的弹窗 -->
        <div class="dialog-container">
          <el-dialog :visible.sync="showInfo" title="IP地址信息">
            <div class="ip-info">
              <div class="info-row">
                <div class="info-item">
                  <span class="info-label">国家：</span>
                  <span class="info-value">{{ ipAddressInfo.country }}</span>
                </div>
                <div class="info-item">
                  <span class="info-label">省份：</span>
                  <span class="info-value">{{ ipAddressInfo.region }}</span>
                </div>
              </div>
              <div class="info-row">
                <div class="info-item">
                  <span class="info-label">城市：</span>
                  <span class="info-value">{{ ipAddressInfo.city }}</span>
                </div>
                <div class="info-item">
                  <span class="info-label">服务商：</span>
                  <span class="info-value">{{ ipAddressInfo.org }}</span>
                </div>
              </div>
            </div>
          </el-dialog>
        </div>
        <el-transfer
          ref="transfer"
          v-model="rightIndex"
          filterable
          filter-placeholder="请输入节目名称"
          :filter-method="search"
          :titles="['源', '导出']"
          :left-default-checked="leftChecked"
          :props="{
            key: 'index',
            label: 'name'
          }"
          :format="{
            noChecked: '${total}',
            hasChecked: '${checked}/${total}'
          }"
          :data="list"
          @change="change">
          <span class="item" slot-scope="{ option }">{{ option.no }} - {{ option.name }}{{ option.resolution === ''? '' : ' - ' + option.resolution }}{{ option.frameRate === ''? '' : ' - ' + option.frameRate }}</span>
          <div class="left-buttons-container" slot="left-footer">
            <span :class="`toggle-button${activeIndex === 0? ' active' : isDisabled? ' disabled' : ''}`" @click="selectResolution(0)">超高清</span>
            <span :class="`toggle-button${activeIndex === 1? ' active' : isDisabled? ' disabled' : ''}`" @click="selectResolution(1)">超清</span>
            <span :class="`toggle-button${activeIndex === 2? ' active' : isDisabled? ' disabled' : ''}`" @click="selectResolution(2)">高清</span>
            <span :class="`toggle-button${activeIndex === 3? ' active' : isDisabled? ' disabled' : ''}`" @click="selectResolution(3)">标清</span>
          </div>
          <div class="right-buttons-container" slot="right-footer">
            <div class="output-container">
              <el-button type="success" size="mini" @click="save('TXT')">TXT格式</el-button>
              <el-button type="success" size="mini" @click="save('M3U')">M3U格式</el-button>
            </div>
            <el-button type="primary" size="mini" @click="save('COPY')">复制</el-button>
          </div>
        </el-transfer>
      </div>
    </div>
  </div>
</template>

<script>
import userService from '@/api/services/userService';
export default {
  name: 'Parse',
  data() {
    return {
      // 控制数据的加载状态
      loading: false,
      // 左侧已选项
      leftChecked: [],
      // 右侧值
      rightIndex: [],
      // 解析类型
      type: '',
      // 主机
      host: '',
      // 端口
      port: '',
      // 是否显示IP地址信息弹窗
      showInfo: false,
      // IP地址信息
      ipAddressInfo: {
        country: "CN",
        region: "Tianjin",
        city: "Tianjin",
        org: "Unicom"
      },
      // 左侧底部按钮激活索引
      activeIndex: -1,
      // 左侧底部按钮是否禁用
      isDisabled: false,
      // 结果初始数据
      list: [
      ],
    };
  },
  mounted() {
    // 解析源
    const src = this.$route.query.src;
    // 使用 ":" 分割字符串
    const parts = src.split(":");
    // 主机
    this.host = parts[0];
    // 端口
    this.port = parts[1];
    // 解析类型
    this.type = this.$route.query.type;
    this.getData();
  },
  methods: {
    // 获取数据
    getData() {
      // 数据加载中
      this.loading = true;
      // 普通解析禁用按钮
      if (this.type === 'basic') {
        this.isDisabled = true;
      // 高级解析不禁用按钮
      } else if (this.type === 'plus') {
        this.isDisabled = false;
      }
      // 准备请求参数
      const params = {
          type: this.type,
          host: this.host,
          port: this.port
      };
      // 通过 userService 的 getParseResult 方法获取搜索结果
      userService.getParseResult(params).then(response => {
        this.ipAddressInfo = response.data.ip_address_info;
        // 从响应中获取必要的信息
        this.list = response.data.list.map((item, index) => {
          // 默认全选
          this.leftChecked.push(index);
          // 返回表格数据对象
          return {
            no: (index + 1),
            index: index,
            name: item.name,
            url: item.url,
            resolution: item.resolution,
            frameRate: item.frame_rate,
            disabled: false,
          };
        });
      })
      .catch(error => {
        console.error(error); // 捕获并输出错误
      })
      .finally(() => {
        // 数据加载完毕
        this.loading = false;
      });
    },
    // 显示或隐藏IP地址信息弹窗
    toggleInfo() {
      this.showInfo = !this.showInfo;
    },
    // 搜索节目名称
    search(value, item) {
      return item.name.indexOf(value) > -1;
    },
    // 移动节目列表
    change(value, direction, keys) {
      if (direction === 'right') {
        this.activeIndex = -1;
      }
    },
    // 选择视频分辨率
    selectResolution(index) {
      // 改变激活索引
      this.activeIndex = index;

      // 可选分辨率
      const resolution = ['4320p | 2880p | 2160p | 1440p', '1080p', '720p', '576p | 480p | 450p'];

      // 清空左侧已选项数组
      this.$refs.transfer.$refs.leftPanel.checked = [];

      // 使用正则表达式匹配分辨率中的数字部分
      const matches = [...resolution[index].matchAll(/(\d+)p/g)];

      // 检查是否有匹配结果
      if (matches) {
        // 遍历匹配结果数组
        matches.forEach(match => {
            // 遍历列表中的每个元素
            this.list.forEach((item, itemIndex) => {
                // 检查当前元素的分辨率是否与给定的高度相同
                if (item.resolution.split('x')[1] === match[1]) {
                    // 如果包含，并且当前索引不在左侧已选项数组中，才添加到左侧已选项数组中
                    if (!this.$refs.transfer.$refs.leftPanel.checked.includes(itemIndex)) {
                        this.$refs.transfer.$refs.leftPanel.checked.push(itemIndex);
                    }
                }
            });
        });
      }
    },
    downloadFile(data, filename) {
      // 创建一个Blob对象，将数据放入其中
      const blob = new Blob([data], { type: 'text/plain;charset=utf-8' });
      // 创建一个<a>元素
      const a = document.createElement('a');
      // 创建一个URL对象，指向Blob对象
      const url = window.URL.createObjectURL(blob);
      // 设置<a>元素的属性，指定下载的文件名和URL
      a.href = url;
      a.download = filename;
      // 将<a>元素添加到文档中
      document.body.appendChild(a);
      // 模拟点击<a>元素进行下载
      a.click();
      // 移除<a>元素
      document.body.removeChild(a);
      // 释放URL对象
      window.URL.revokeObjectURL(url);
    },
    save(type) {
      // 判断是否选择了任何项
      if (this.rightIndex.length !== 0) {
        // 如果选择了项
        if (type === 'TXT') {
          // 构建TXT文件内容
          let txtContent = '';
          // 遍历列表，添加每个直播源的信息
          this.list.forEach((item, index) => {
            if (this.rightIndex.includes(index)) {
              // 将选定的直播源名称和URL添加到TXT内容中
              txtContent += `${item.name},${item.url}\n`;
            }
          });
          // 保存为TXT文件
          this.downloadFile(txtContent, 'channels.txt');
          // 成功提示
          this.$message({
            message: '下载成功',
            type: 'success',
            duration: 1000
          });
        } else if (type === 'M3U') {
          // 构建M3U文件内容
          let m3uContent = '#EXTM3U\n\n'; // 文件开头
          // 遍历列表，添加每个直播源的信息
          this.list.forEach((item, index) => {
            if (this.rightIndex.includes(index)) {
              // 将选定的直播源名称和URL添加到M3U内容中
              m3uContent += `#EXTINF:-1,${item.name}\n${item.url}\n`;
            }
          });
          // 保存为M3U文件
          this.downloadFile(m3uContent, 'channels.m3u');
          // 成功提示
          this.$message({
            message: '下载成功',
            type: 'success',
            duration: 1000
          });
        } else if (type === 'COPY') {
          // 构建复制内容
          let copyContent = '';
          // 遍历列表，添加每个直播源的信息
          this.list.forEach((item, index) => {
            if (this.rightIndex.includes(index)) {
              // 将选定的直播源名称和URL添加到复制内容中
              copyContent += `${item.name},${item.url}\n`;
            }
          });
          // 调用全局的复制服务
          this.$clipboard(copyContent).then(() => {
            this.$message({
              message: '已复制到剪贴板',
              type: 'success',
              duration: 1000
            });
          })
          .catch(() => {
            this.$message.error('复制失败');
          });
        }
      } else {
        // 如果未选择任何项，则显示错误消息
        this.$message.error('导出数据是空的，无法操作');
      }
    },
  },
};
</script>

<style scoped lang="scss">
#parse ::v-deep {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .header-container {
    padding: 20px 0;
    text-align: center;
    .chevron-left {
      font-size: 30px;
      color: #1E9FFF;
      cursor: pointer;
    }
    .title {
      font-family: '方正王献之小楷 简';
      font-size: 35px;
      font-weight: bold;
      color: #1E9FFF;
    }
  }

  .content-container {
    flex: 1;
    padding: 0 30px 30px 30px;
    .floating-icon {
      position: fixed;
      left: -50px; /* 部分隐藏在屏幕外 */
      top: 50%;
      transform: translateY(-50%);
      width: 60px;
      height: 60px;
      background-color: #1E9FFF;
      color: white;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
      cursor: pointer;
      transition: left 0.3s ease-in-out;
      z-index: 1000;
      &:hover {
        left: 0; /* 当鼠标悬停时，完全显示按钮 */
      }
      i {
        font-size: 24px;
      }
    }
    /* 移动端样式（小于 1200px） */
    @media screen and (max-width: 1200px) {
      .dialog-container {
        .el-dialog {
          width: 90% !important;
        }
      }
    }
    .dialog-container {
      .ip-info {
        .info-row {
          display: flex;
          justify-content: space-around;
          align-items: center;
          margin-bottom: 30px;
          .info-item {
            .info-label {
              margin-right: 5px;
              font-weight: bold;
              color: #333;
            }
            .info-value {
              color: #666;
            }
          }
        }
      }
    }
    .transfer-container {
      width: 100%;
      .ip-address-info-card {
        margin-bottom: 30px;
      }
      .el-transfer {
        .el-transfer__buttons {
        }
        .el-transfer-panel {
          width: 40%;
          text-align: left;
          .el-transfer-panel__footer {
            display: flex;
            justify-content: center;
            align-items: center;
            .left-buttons-container {
              width: 100%;
              display: flex;
              justify-content: space-around;
              .toggle-button {
                display: inline-block;
                line-height: 1;
                color: #606266;
                text-align: center;
                margin: 0;
                padding: 7px 15px;
                font-size: 12px;
                font-weight: 500;
                border-radius: 3px;
                border: 1px solid #DCDFE6;
                background: #FFF;
                transition: .1s;
                outline: 0;
                box-sizing: border-box;
                white-space: nowrap;
                cursor: pointer;
                -webkit-appearance: none;
                &.active {
                  color: #409EFF;
                  border-color: #C6E2FF;
                  background-color: #ECF5FF;
                }
                &.disabled {
                  color: #999;
                  background-color: #F2F2F2;
                  border-color: #CCC;
                  cursor: not-allowed;
                  pointer-events: none;
                }
              }
            }
            .right-buttons-container {
              width: 100%;
              display: flex;
              justify-content: space-around;
              .buttons-container {
              }
            }
          }
        }
      }
      /* 移动端样式（小于 1200px） */
      @media screen and (max-width: 1200px) {
        .el-transfer {
          /* 穿梭框面板样式 */
          .el-transfer-panel {
            width: 100% !important;
            .el-transfer-panel__body {
              height: 400px;
              .el-checkbox-group.el-transfer-panel__list {
                height: 353px;
              }
            }
          }

          /* 穿梭框移动选项按钮组样式 */
          .el-transfer__buttons {
            display: block;
            .el-button.el-button--primary.el-transfer__button {
              display: block;
              margin: 20px auto;
              .el-icon-arrow-left {
                &::before {
                  content: "\e6e1";
                }
              }
              .el-icon-arrow-right {
                &::before {
                  content: "\e6df";
                }
              }
            }
          }
        }
      }
      /* PC端样式（大于等于 1200px） */
      @media screen and (min-width: 1200px) {
        .el-transfer {
          display: flex;
          justify-content: center;
          align-items: center;
          /* 穿梭框面板样式 */
          .el-transfer-panel {
            flex: 1;
            .el-transfer-panel__body {
              height: 400px;
              .el-checkbox-group.el-transfer-panel__list {
                height: 353px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
