import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/views/Home.vue'
import About from '@/views/About.vue'

import Result from '@/views/result/Index.vue'
import Search from '@/views/result/Search.vue'
import Parse from '@/views/result/Parse.vue'

import Auth from '@/views/auth/Index.vue'
import Login from '@/views/auth/Login.vue'
import Register from '@/views/auth/Register.vue'
import Reset from '@/views/auth/Reset.vue'

import PageNotFound from '@/views/PageNotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      keepAlive: true // 设置路由组件需要缓存
    }
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/result',
    name: 'Result',
    component: Result,
    children: [
      {
        path: 'search',
        name: 'Search',
        component: Search
      },
      {
        path: 'parse',
        name: 'Parse',
        component: Parse
      },
    ]
  },
  {
    path: '/auth',
    name: 'Auth',
    component: Auth,
    redirect: '/auth/login', // 默认重定向到登录页面
    children: [
      {
        path: 'login',
        name: 'Login',
        component: Login
      },
      {
        path: 'register',
        name: 'Register',
        component: Register
      },
      {
        path: 'reset',
        name: 'Reset',
        component: Reset
      }
    ]
  },
  {
    path: '/404',
    name: 'PageNotFound',
    component: PageNotFound
  },
  {
    path: '*', // 捕获所有未匹配到的路由
    redirect: '/404', // 重定向到404页面
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/frontend/', // 设置基本路径
  routes
})

export default router
