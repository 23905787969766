export const API_URLS = {
  REFRESH_TOKEN: '/token/refresh',
  LOGIN: '/login',
  LOGOUT: '/logout',
  CODE: '/code',
  REGISTER: '/register',
  RESET: '/reset',
  LIST_TASKS: '/task',
  CURRENT_TASKS: '/task/current',
  SAVE_TASK: '/task/save',
  MANAGE_TASK: '/task/manage',
  UPDATE_TASK: '/task/update',
  DELETE_TASK: '/task/delete',
  TASK_PROVINCES: '/task/provinces',
  TASK_CITIES: '/task/cities',
  LIST_DDNS: '/ddns',
  ADD_DDNS: '/ddns/add',
  UPDATE_DDNS: '/ddns/update',
  DELETE_DDNS: '/ddns/delete',
  SCAN_DDNS: '/ddns/scan',
  LIST_USERS: '/user',
  KEYWORDS: '/user/keyword',
  SEARCH: '/user/result/search',
  PARSEBASIC: '/user/result/parse/basic',
  PARSEPLUS: '/user/result/parse/plus',
  USER_INFO: '/user/info',
  UPDATE_USER: '/user/update',
  DELETE_USER: '/user/delete',
};
