<template>
  <div class="unauthorized-container">
    <div class="icon"><i class="fas fa-ban"></i></div>
    <span class="tip">很抱歉，你无权访问此页面！🙅‍♀️</span>
  </div>
</template>

<script>
export default {
  name: 'Unauthorized',
}
</script>

<style scoped lang="scss">
.unauthorized-container {
  width: 300px;
  margin: 0 auto;
  margin-top: 150px;
  .icon {
    font-size: 100px;
    color: #F56C6C;
  }
  .tip {
    display: block;
    margin: 20px 0;
    color: #F56C6C;
  }
  
}
</style>
