/**
 * 使用示例：
 * 节流指令使用方法：v-throttle="{func: test, type: 'keydown'}" 或 v-throttle:1000="{func: test, type: 'scroll'}"
 * 防抖指令使用方法：v-debounce:2000.immediate="test"
 * 
 * 使用说明：
 * - v-throttle 指令用于节流，可以设置延时时间或者直接传入对象包含 func 和 type 属性，其中 func 为目标函数，type 为事件类型，默认为点击事件。
 * - v-debounce 指令用于防抖，可以设置延时时间和是否立即执行目标函数。
 *
 * 防抖和节流指令：
 * - 防抖指令用于限制函数的执行频率，当某个事件连续触发时，只有在指定的时间间隔之后才执行目标函数。
 * - 节流指令用于限制函数的执行频率，当某个事件连续触发时，在指定的时间间隔内最多执行一次目标函数。
 */

export default {
    install(Vue) {
        const config = {
            bind(el, binding) {
                const { value, arg, modifiers } = binding;
                let immediate = false;
                let wait = 500;
                let type = 'click';
                let params = [];
                let directiveName = binding.name; // 指令名称

                if (value === null) return;
                if (typeof value !== 'object' && typeof value !== 'function') return;
                if (arg) wait = +arg;
                if (modifiers && modifiers.immediate) immediate = true;
                let func;
                if (typeof value === 'function') func = value;
                if (typeof value === 'object') {
                    func = value.func || function() {};
                    type = value.type || 'click';
                    params = value.params || [];
                }

                el.$type = type;
                el.$handle = (function(func, wait, immediate, params) {
                    let timer;
                    return function proxy(...args) {
                        const context = this;
                        const later = function() {
                            timer = null;
                            if (!immediate) func.apply(context, args);
                        };
                        const callNow = immediate && !timer;
                        clearTimeout(timer);
                        timer = setTimeout(later, wait);
                        if (callNow) func.apply(context, args);
                    };
                })(func, wait, immediate, params);

                el.addEventListener(el.$type, el.$handle);
            },
            unbind(el) {
                el.removeEventListener(el.$type, el.$handle);
                delete el.$type; // 清除存储在元素上的属性
                delete el.$handle;
            }
        };

        Vue.directive('debounce', config);
        Vue.directive('throttle', config);
    }
};
