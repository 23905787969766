<template>
  <div id="auth">
    <div class="flip-container">
      <transition name="flip" mode="out-in">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.flip-enter-active, .flip-leave-active {
  transition: transform 0.5s ease-in-out;
}
.flip-enter {
  transform: rotateY(-180deg);
  backface-visibility: hidden; /* 确保背面内容不可见 */
}
.flip-leave-to {
  transform: rotateY(180deg);
  backface-visibility: hidden; /* 确保背面内容不可见 */
}
.flip-enter-to, .flip-leave {
  transform: rotateY(0deg);
  backface-visibility: hidden; /* 确保背面内容不可见 */
}

#auth {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .flip-container {
    perspective: 1000px;
  }
}
</style>
