import { apiInstance, API_URLS } from '@/api';

const subService = {
  // 获取动态 DNS 列表
  getDDNSList(data) {
    return apiInstance.get(API_URLS.LIST_DDNS, {
      params: data,
      headers: {
        requiresAuth: true
      },
      timeout: 10 * 1000, // 设定超时时间为10秒钟
      errorMessageDuration: 3 * 1000 // 设定错误消息显示时间
    });
  },
  // 添加 DDNS
  addDDNS(data) {
    return apiInstance.post(API_URLS.ADD_DDNS, data, {
      headers: {
        requiresAuth: true
      },
      timeout: 10 * 1000, // 设定超时时间为10秒钟
      errorMessageDuration: 3 * 1000 // 设定错误消息显示时间
    });
  },
  // 更新 DDNS
  updateDDNS(data) {
    return apiInstance.post(API_URLS.UPDATE_DDNS, data, {
      headers: {
        requiresAuth: true
      },
      timeout: 10 * 1000, // 设定超时时间为10秒钟
      errorMessageDuration: 3 * 1000 // 设定错误消息显示时间
    });
  },
  // 删除 DDNS
  deleteDDNS(data) {
    return apiInstance.post(API_URLS.DELETE_DDNS, data, {
      headers: {
        requiresAuth: true
      },
      timeout: 30 * 1000, // 设定超时时间为30秒钟
      errorMessageDuration: 3 * 1000 // 设定错误消息显示时间
    });
  },
};

export default subService;
