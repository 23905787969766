<template>
  <div id="result">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Index',
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
#result {
}
</style>
