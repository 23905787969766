<template>
  <div id="search">
    <!-- 头部容器 -->
    <div class="header-container">
      <!-- 返回按钮 -->
      <router-link class="chevron-left" to="/"><i class="fas fa-chevron-left"></i></router-link>
      <!-- 标题 -->
      <h1 class="title">搜索结果</h1>
    </div>
    <!-- 内容容器 -->
    <div class="content-container">
      <!-- 加载状态 -->
      <el-skeleton class="search-tags-loading" v-if="loading" :loading="true" :rows="3" animated></el-skeleton>
      <!-- 搜索结果列表 -->
      <div class="result-list" v-if="!loading && resultData.length !== 0">
        <template v-if="action === 'src'">
          <div class="result-item" v-for="(item, index) in resultData" :key="index">
            <div class="result-item-content">
              <div class="result-item-url" @click="copy(item.src)">
                <div class="copy"><i class="far fa-copy"></i></div>
                <div class="text url">{{ item.src }}</div>
              </div>
              <div class="result-item-details">
                <span class="result-item-time">{{ item.checkTime }}</span>
                <span class="result-item-time">{{ item.survivalTime === 0? '新上线' : '存活' + item.survivalTime + '天' }}</span>
                <span class="result-item-channels">频道{{ item.channels }}个</span>
                <span class="result-item-location">{{ item.location }}</span>
              </div>
            </div>
          </div>
        </template>
        <template v-else-if="action === 'keyword'">
          <div class="result-item" v-for="(item, index) in resultData" :key="index">
            <div class="result-item-content">
              <div class="result-item-url" @click="copy(item.url)">
                <div class="copy"><i class="far fa-copy"></i></div>
                <div class="text name">{{ item.name }}</div>
                <div class="text url">{{ item.url }}</div>
              </div>
              <div class="result-item-details">
                <span class="result-item-time">{{ item.time }}</span>
                <span class="result-item-resolution">{{ item.resolution }}</span>
              </div>
            </div>
            <div class="arrow-right" @click="toWeb(item.url)"><i class="fas fa-arrow-right"></i></div>
          </div>
          <!-- 分页容器 -->
          <div class="pagination-container" v-if="total !== pageSize">
            <!-- 分页组件 -->
            <el-pagination background hide-on-single-page :current-page="page" :page-size="pageSize" :total="total" layout="total, prev, pager, next, jumper" @current-change="changePage"></el-pagination>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import userService from '@/api/services/userService';
export default {
  name: 'Search',
  data() {
    return {
      loading: false, // 控制数据的加载状态
      action: 'src', // 动作类型
      key: '', // 键
      keyword: '', // 关键词
      // 结果初始数据
      resultData: [
      ],
      // 列表当前页
      page: 0,
      // 列表页大小
      pageSize: 0,
      // 列表总记录
      total: 0,
    };
  },
  mounted() {
    this.key = this.$route.query.key;
    this.keyword = this.$route.query.keyword;
    this.action = this.isIPAddressOrDomain(this.keyword)? 'src' : 'keyword';
    this.getData();
  },
  methods: {
    // 匹配 IP 地址或域名
    isIPAddressOrDomain(str) {
        // IP 地址的正则表达式
        const ipRegex = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;
        // 域名的正则表达式
        const domainRegex = /^[a-zA-Z0-9]+([-.][a-zA-Z0-9]+)*\.[a-zA-Z]{2,}(:[0-9]{1,5})?$/;

        // 使用正则表达式进行匹配
        if (ipRegex.test(str)) {
            return true;
        } else if (domainRegex.test(str)) {
            return true;
        } else {
            return false;
        }
    },
    // 获取数据
    getData() {
      // 数据加载中
      this.loading = true;
      // 准备请求参数
      const params = {
          key: this.key,
          keyword: this.keyword
      };
      // 如果页面数不为0，则设置页面数
      if (this.page !== 0) {
          params.page = this.page;
      }
      // 如果页面大小不为0，则设置页面大小
      if (this.pageSize !== 0) {
          params.page_size = this.pageSize;
      }
      // 通过 userService 的 getSearchResult 方法获取搜索结果
      userService.getSearchResult(params).then(response => {
        // 从响应中获取必要的信息
        this.total = response.data.total;
        this.pageSize = response.data.page_size;
        this.resultData = response.data.list;
      })
      .catch(error => {
        console.error(error); // 捕获并输出错误
      })
      .finally(() => {
        // 数据加载完毕
        this.loading = false;
      });
    },
    // 当前页码变化时
    changePage(page) {
      this.page = page;
      this.resultData = [];
      this.getData();
    },
    copy(url) {
      if (url) {
        this.$clipboard(url)
        .then(() => {
          this.$message({
            message: '已复制到剪贴板',
            type: 'success',
            duration: 1000
          });
        })
        .catch(() => {
          this.$message.error('复制失败');
        });
      }
    },
    toWeb(url) {
      // 在新标签页中打开链接
      window.open(url, '_blank');
    }
  },
};
</script>

<style scoped lang="scss">
#search {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  /* 移动端样式（小于 768px） */
  @media screen and (max-width: 767px) {
    /* 分页组件横向滚动样式 */
    .pagination-container {
      overflow-x: scroll;
    }
  }

  /* PC端样式（大于等于 768px） */
  @media screen and (min-width: 768px) {
  }

  .header-container {
    padding: 20px 0;
    text-align: center;
    .chevron-left {
      font-size: 30px;
      color: #1E9FFF;
      cursor: pointer;
    }
    .title {
      font-family: '方正王献之小楷 简';
      font-size: 35px;
      font-weight: bold;
      color: #1E9FFF;
    }
  }

  .content-container {
    flex: 1;
    padding: 0 30px;
    .result-list {
      .result-item {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 20px;
        padding: 15px; /* 添加内边距 */
        border: 1px solid #e4e7ed; /* 添加边框 */
        border-radius: 5px; /* 圆角边框 */
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 添加阴影效果 */
        transition: box-shadow 0.3s ease; /* 添加过渡动画 */
        &:hover {
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 鼠标悬停时增加阴影 */
        }

        .result-item-content {
          width: 80%;
          .result-item-url {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            cursor: pointer;
            overflow: hidden; /* 隐藏溢出部分 */
            .copy {
              width: 20px;
              font-size: 20px; /* 图标大小 */
              color: #8492A6; /* 图标颜色 */
            }
            .text {
              margin-left: 10px;
              text-align: left;
              font-weight: bold; /* 加粗文字 */
              &.url {
                color: #1E9FFF; /* 蓝色文字 */
                white-space: nowrap; /* 防止文本换行 */
                overflow: hidden; /* 隐藏溢出部分 */
                text-overflow: ellipsis; /* 使用省略号表示溢出的文本 */
              }
              &.name {
                // max-width: 200px;
                text-align: center;
                color: #67C23A; /* 绿色文字 */
                white-space: nowrap; /* 防止文本换行 */
              }
            }
          }
          .result-item-details {
            display: flex;
            justify-content: space-between;
            align-items: center; /* 垂直居中 */
            font-size: 14px; /* 字体大小 */
            color: #8492A6; /* 次要文本颜色 */

            .result-item-time,
            .result-item-channels {
            }

            .result-item-resolution {
            }
          }
        }
        .arrow-right {
          font-size: 20px; /* 图标大小 */
          color: #1E9FFF; /* 图标颜色 */
          cursor: pointer;
        }
      }
    }
    /* 分页组件样式 */
    .pagination-container {
      margin: 30px 0;
    }
  }
}
</style>
