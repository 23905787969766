import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueClipboard from 'v-clipboard';
import Directive from './directives';
import MusicPlayPlugin from './plugins';
// 优化页面的滑动性能
import 'default-passive-events';



Vue.use(VueClipboard);
Vue.use(ElementUI);
Vue.use(Directive);
Vue.use(MusicPlayPlugin);
Vue.config.productionTip = false;



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
