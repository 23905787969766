<template>
  <div id="app">
    <!-- 请求授权提示 -->
    <div class="dialog-container">
      <el-dialog title="请求授权" :visible="optDialog === 'permission'" width="30%" :before-close="closeOptDialog"
        :close-on-click-modal="false">
        <span>允许该网站播放音乐吗？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="optDialog = ''">取消</el-button>
          <el-button type="primary" v-throttle="agree">同意</el-button>
        </span>
      </el-dialog>
    </div>
    <keep-alive :include="cachedViews">
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: 'App',
  computed: {
    // 计算哪些视图需要被缓存
    cachedViews() {
      return this.$router.options.routes.filter(route => route.meta && route.meta.keepAlive).map(route => route.name);
    },
  },
  data() {
    return {
      state: false, // 暂停或播放状态
      isFirstTime: true, // 是否是首次进入
      optDialog: '', // 授权窗口
    };
  },
  mounted() {
    // 初始化播放器
    this.initPlayer();
  },
  methods: {
    // 初始化播放器
    initPlayer() {
      // 获取音乐播放器元素
      const playerElement = document.getElementById('music-player');
      // 设置this的指向
      const that = this;
      // 给播放按钮绑定事件
      playerElement.addEventListener('mousedown', function(e) {
        // 判断播放状态
        if (that.$musicPlayer.state) {
          // 如果正在播放，则暂停播放
          that.$musicPlayer.state = false;
          that.$musicPlayer.stop();
        } else {
          if (that.$musicPlayer.isFirstTime) {
            // 如果是首次操作，显示权限提示对话框
            that.optDialog = 'permission';
          } else {
            // 否则开始播放音乐
            that.$musicPlayer.state = true;
            that.$musicPlayer.play();
          }
        }
      });
    },
    // 关闭对话框
    closeOptDialog() {
      this.optDialog = '';
    },
    // 用户同意授权播放音乐
    agree() {
      this.$musicPlayer.state = true;
      this.$musicPlayer.isFirstTime = false;
      this.$musicPlayer.play();
      this.optDialog = '';
    },
  },
};
</script>

<style lang="scss">
/* 跨浏览器兼容 */
@import '~normalize.css/normalize.css';
/* 引入字体图标 */
@import url('@/assets/css/font-awesome-icon.min.css');

/* 引入字体 */
@font-face {
  font-family: '方正王献之小楷 简';
  src: url('@/assets/fonts/subset-FangZhengWangXianZhiXiaoKai-Regular.ttf') format('truetype');
}

body {
  font-family: sans-serif;
  background: radial-gradient(circle at center, #E4E6DF75, #87CEEB);
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2C3E50;
}

/* 移动端样式（小于 768px） */
@media screen and (max-width: 767px) {
  .dialog-container {
    .el-dialog {
      width: 90% !important;
    }
  }
  .el-message-box {
    width: 90% !important;
  }
}

/* 滚动条宽度和高度 */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
  background: #E0E8F0;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #4682B4;
}

/* 鼠标悬停在滚动条上时的样式 */
::-webkit-scrollbar-thumb:hover {
  background: #5A9BD5;
}

/* 修改 tooltip 样式 */
.el-tooltip__popper.is-dark {
  background-color: #3595CC !important;
  /* 设置背景颜色 */
  color: #fff !important;
  /* 设置文字颜色 */
}

/* 修改 tooltip 小三角样式 */
.el-tooltip__popper.is-dark .popper__arrow::after {
  border-top-color: #3595CC !important;
  /* 设置小三角颜色 */
}
</style>
