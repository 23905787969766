<template>
  <div id="register">
    <div class="register-form">
      <h2 class="register-title">新用户注册</h2>
      <el-form>
        <!-- 用户名输入框 -->
        <el-input class="username-input" v-model="username" type="text" placeholder="请输入用户名" @input="handleInput('username')"></el-input>
        <!-- 邮箱输入框 -->
        <el-input class="email-input" v-model="email" type="email" placeholder="请输入QQ邮箱" @blur="handleEmailBlur('email')"></el-input>
        <div class="code-container">
          <!-- 验证码输入框 -->
          <el-input class="code-input" v-model="code" type="text" placeholder="请输入验证码" @input="handleInput('code')"></el-input>
          <!-- 发送验证码按钮 -->
          <el-button class="send-code" :disabled="codeSending" type="primary" @click="sendCode">{{ codeSending ? `${countdown}s 后重新发送` : '发送验证码' }}</el-button>
        </div>
        <!-- 密码输入框 -->
        <el-input class="password-input" v-model="password" type="password" placeholder="请输入密码" show-password></el-input>
        <!-- 确认密码输入框 -->
        <el-input class="re-password-input" v-model="rePassword" type="password" placeholder="请再次输入密码" show-password></el-input>
      </el-form>
      <!-- 注册按钮 -->
      <el-button class="register-button" type="primary" v-throttle="register">注册</el-button>
      <!-- 跳转到登录按钮 -->
      <el-button class="login-button" type="text" @click="toLogin">已有账号？登录</el-button>
    </div>
  </div>
</template>

<script>
import userService from '@/api/services/userService';

export default {
  name: 'Register',
  data() {
    return {
      username: '', // 用户名
      email: '', // 邮箱
      isVaildEmail: false, // 邮箱是否有效
      code: '', // 验证码
      password: '', // 密码
      rePassword: '', // 确认密码
      codeSending: false, // 是否正在发送验证码
      countdown: 60, // 验证码倒计时
      timer: null, // 计时器
    };
  },
  beforeDestroy() {
    // 组件销毁时清除计时器
    clearInterval(this.timer);
  },
  methods: {
    handleInput(field) {
      if (field === 'username') {
        // 移除空白字符和汉字，保留特殊符号
        this.username = this.username.replace(/[\s\u4e00-\u9fa5]/g, '');
      } else if (field === 'code') {
        // 移除非数字字符
        this.code = this.code.replace(/\D/g, '');
        // 限制输入长度为6
        if (this.code.length > 6) {
          this.code = this.code.slice(0, 6);
        }
      }
    },
    /**
     * 邮箱输入框失去焦点时处理
     */
    handleEmailBlur() {
      // 确保邮箱为 QQ 邮箱格式，且邮箱输入框不为空
      const qqEmailRegex = /^\d+@qq.com$/;
      const email = this.email.trim(); // 去除首尾空格
      if (email && !qqEmailRegex.test(email)) {
        this.$message.error('邮箱格式不正确，请使用QQ邮箱');
        this.isVaildEmail = false;
      } else {
        this.isVaildEmail = true;
      }
    },
    /**
     * 发送验证码
     */
    sendCode() {
      // 检测邮箱是否有效
      if (!this.isVaildEmail) {
        this.$message.error('请检查您的邮箱');
        return;
      }

      // 防止重复点击
      if (this.codeSending) return;

      // 设置发送验证码中的状态
      this.codeSending = true;
      this.countdown = 60; // 设置倒计时初始值为60秒

      // 定时器，每秒减少倒计时的值，直到为0
      this.timer = setInterval(() => {
          if (this.countdown > 0) {
              this.countdown--;
          } else {
              clearInterval(this.timer); // 清除定时器
              this.codeSending = false; // 恢复发送验证码状态为false
              this.countdown = 60; // 重置倒计时为60秒
          }
      }, 1000);

      // 准备请求参数
      const params = {
        action: 'register',
        email: this.email
      };
      // 发送验证码
      userService.sendCode(params)
      .then(response => {
          // 输出成功提示
          this.$message.success(response.message);
      })
      .catch(error => {
          // 输出错误提示
          this.$message.error(error.data.info);
          clearInterval(this.timer); // 请求失败时需要清除定时器
          this.codeSending = false; // 请求失败时也要恢复发送验证码状态为false
          this.countdown = 60; // 请求失败时重新设置倒计时为60秒
      });
    },
    /**
     * 注册
     */
    register() {
      // 检查参数是否为空
      if (!this.username || !this.email || !this.code || !this.password || !this.rePassword) {
        this.$message.error('请填写完整的信息');
        return;
      }

      // 检查密码和确认密码是否一致
      if (this.password !== this.rePassword) {
        this.$message.error('密码不一致，请重新输入。');
        return;
      }

      // 限定密码规则
      const regex = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d\p{P}]{5,20}$/u;
      if (!regex.test(this.password)) {
        this.$message.error({
            message: '密码必须包含至少一个字母和一个数字，并且长度必须在5到20个字符之间，且只能包含英文字母、数字和符号（不包含空白符号）',
            duration: 3000
        });
        return;
      }

      // 准备请求参数
      const params = {
        username: this.username,
        email: this.email,
        code: this.code,
        password: this.password,
      };

      // 注册
      userService.register(params)
      .then(response => {
        // 输出成功提示
        this.$message.success(response.message);
        // 延迟1秒后跳转到登录界面
        setTimeout(() => {
          this.$router.push('/auth/login');
        }, 1000);
      })
      .catch(error => {
        console.error(error);
      });
    },
    /**
     * 导航到登录页面
     */
    toLogin() {
      this.$router.push('/auth/login');
    },
  },
};
</script>

<style scoped lang="scss">
#register {
  /* 移动端样式（小于 768px） */
  @media screen and (max-width: 768px) {
    .register-form {
      width: 90% !important;
      margin: 0 auto;
      box-sizing: border-box;
    }
  }

  .register-form {
    width: 300px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;

    .register-title {
      margin-bottom: 30px;
    }
    .username-input,
    .email-input,
    .password-input {
      margin-bottom: 10px;
    }
    .re-password-input {
      margin-bottom: 20px;
    }

    .code-container {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .send-code {
        margin-left: 10px;
      }
    }
    .el-button+.el-button {
      margin-left: 0;
    }
  }
}
</style>
