<template>
  <div class="current-task-container">
    <el-popover placement="right" title="任务内容" width="200" trigger="manual" v-model="popoverVisible" ref="popover">
      <div v-html="richContent"></div>
      <div class="progress-container" slot="reference" @click="showPopover">
        <el-progress type="dashboard" :percentage="percentage" :show-text=false :color="color[`${getProgressType}`]"
          define-back-color="#CCC"></el-progress>
        <div :class="`progress-text ${getProgressType}`">{{ progressText }}</div>
        <div class="progress-button">
          <el-button type="primary" @click.stop="toTab">查看所有任务</el-button>
        </div>
      </div>
    </el-popover>
  </div>
</template>

<script>
import taskService from '@/api/services/taskService';
export default {
  name: 'CurrentTask',
  computed: {
    getProgressType() {
      const stage = this.stage; // 任务阶段
      // 判断阶段是否包含 '_wait' 字符串
      const containsSubStr = stage.includes('_wait');
      if (containsSubStr) {
        // 如果阶段包含 '_wait' 字符串，则返回wait，表示等待中
        return 'wait'
      } else {
        if (stage === 'ready') {
          // 如果阶段为ready，则返回ready，表示准备中
          return 'ready'
        } else if (stage === 'merge') {
          // 如果阶段为merge，则返回ready，表示准备中
          return 'ready'
        } else if (stage === 'split') {
          // 如果阶段为split，则返回ready，表示准备中
          return 'ready'
        } else if (stage === 'done') {
          // 如果阶段为ready，则返回done，表示已完成
          return 'done'
        } else if (stage === 'stop') {
          // 如果阶段为stop，则返回stop，表示已停止
          return 'stop'
        } else {
          // 否则返回active，表示进行中
          return 'active'
        }
      }
    }
  },
  data() {
    return {
      popoverVisible: false,
      percentage: 0,
      color: {
        ready: '#909399',
        active: '#409EFF',
        wait: '#E6A23C',
        done: '#67C23A',
        stop: '#F56C6C',
      },
      stage: '',
      progressText: '',
      richContent: '',
    };
  },
  mounted() {
    // 获取数据
    this.getData();

    // 设定定时器
    this.setupTimer();
  },
  beforeDestroy() {
    // 在组件销毁前清除定时器
    this.clearTimer();
    // 移除点击事件监听
    document.removeEventListener('click', this.handleDocumentClick);
  },
  activated() {
    this.setupTimer();
  },
  deactivated() {
    this.clearTimer();
  },
  methods: {
    setupTimer() {
      // 清除现有的定时器以避免重复
      this.clearTimer();

      // 启动定时器，并将其保存在组件的 timer 属性中
      this.timer = setInterval(() => {
        this.getData();
      }, 10000); // 10000 毫秒表示10秒
    },
    clearTimer() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null; // 清除后重置定时器标识
      }
    },
    // 获取数据
    getData() {
      // 通过 taskService 获取当前任务信息
      taskService.getCurrentTask().then(response => {
        // 提取响应中的任务 id、阶段和进度信息
        const ids = response.data.ids; // 任务 id 数组
        const stage = response.data.stage; // 任务阶段
        const progress = response.data.progress; // 任务进度
        const tasks = response.data.tasks; // 当前任务
        
        this.stage = stage;
        // 判断阶段是否包含 '_wait' 或者 'stop' 字符串
        const containsSubStr = stage.includes('_wait') || stage.includes('stop');
        // 获取阶段名称
        const stageName = this.getStageName(stage);
        if (containsSubStr) {
          // 如果阶段包含 '_wait' 字符串，则状态为阶段名称，并且状态是未激活
          this.progressText = stageName;
        } else {
          // 否则状态为阶段名称 + 进度
          this.progressText = progress + ' ' + stageName;
        }
        // 去掉 progress 变量末尾的 %
        this.percentage = parseFloat(progress.replace(/%$/, ''));

        // 任务数组不为0才处理
        if (tasks.length !== 0) {
          // 获取第一个任务的详细信息
          const item = tasks[0];
          // 提取任务列表中的地理位置
          const locations = tasks.map(task => task.location).join('、');
          // 提取任务列表中的资源路径和关键词
          const uris = tasks.map(task => task.uri).join('、');
          const keywords = tasks.map(task => task.keyword).join('、');
          // 提取任务列表中的邮箱信息
          const emails = tasks.filter(task => task.email !== undefined).map(task => task.email).join('、');
          // 提取任务列表中的备注信息
          const remarks = tasks.map(task => task.remark).join('、');
          // 构建富文本内容
          this.richContent = `
            <p>扫描方式：${item.mode_text}</p>
            <p>地理位置：${locations}</p>
            <p>端口：${item.port}</p>
            <p>资源路径：${uris}</p>
            <p>关键词：${keywords}</p>
            ${emails? `<p>联系人：${emails}</p>` : ''}
            <p>备注：${remarks}</p>
          `;
        }
      })
      .catch(error => {
        console.error(error); // 捕获并输出错误
      });
    },
    // 根据阶段名称获取对应的状态名称
    getStageName(stage) {
      // 使用 switch-case 结构根据不同的阶段名称返回对应的状态名称
      switch (stage) {
        case 'ready':
          return '准备';
        case 'merge':
          return '合并';
        case 'split':
          return '分块';
        case 'discover':
          return '发现';
        case 'discover_wait':
          return '发现即将结束';
        case 'scan':
          return '扫描';
        case 'scan_wait':
          return '扫描即将结束';
        case 'check':
          return '检测';
        case 'check_wait':
          return '检测即将结束';
        case 'done':
          return '已完成';
        case 'stop':
          return '已停止';
        default:
          return '未知阶段'; // 如果阶段未知，则返回默认值
      }
    },
    showPopover() {
      this.popoverVisible = !this.popoverVisible;
      // 监听整个页面的点击事件
      document.addEventListener('click', this.handleDocumentClick);
    },
    handleDocumentClick(event) {
      // 获取 el-popover 实例
      const popover = this.$refs.popover;
      // 判断点击的区域是否在 el-popover 内部
      if (!popover.$el.contains(event.target)) {
        this.popoverVisible = false;
        // 移除点击事件监听
        document.removeEventListener('click', this.handleDocumentClick);
      }
    },
    toTab() {
      this.$emit('change', 'fourth');
    },
  },
}
</script>

<style scoped lang="scss">
.current-task-container ::v-deep {
  .progress-container {
    display: inline-block;
    position: relative;
    margin-top: 50px;
    text-align: center;
  }

  .progress-container .el-progress-circle {
    width: 300px !important;
    height: 300px !important;
  }

  .progress-container .progress-text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    &.ready {
      color: #909399;
    }
    &.active {
      color: #409EFF;
    }
    &.wait {
      color: #E6A23C;
    }
    &.done {
      color: #67C23A;
    }
    &.stop {
      color: #F56C6C;
    }
  }

  .progress-container .progress-button {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
  }
}
</style>
