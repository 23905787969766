<template>
  <div id="login">
    <div class="cat-container">
      <div class="cat"><i class="fas fa-cat"></i></div>
      <div class="eye-left"></div>
      <div class="eye-right"></div>
      <div class="fish-tank" @click="toAbout" draggable @dragstart="toAbout"></div>
      <h2 class="cat-bubble"></h2>
    </div>
    <div class="login-form">
      <h2 class="login-title">欢迎登录</h2>
      <el-form>
        <el-input class="account-input" v-model="account" type="text" placeholder="请输入用户名或邮箱"></el-input>
        <el-input class="password-input" v-model="password" type="password" placeholder="请输入密码" show-password @keyup.enter.native="login"></el-input>
      </el-form>
      <el-button class="login-button" type="primary" v-throttle="login">登录</el-button>
      <div class="text-button-group">
        <el-button class="register-button" type="text" @click="toRegister">注册账号</el-button>
        <el-button class="reset-button" type="text" @click="toReset">找回密码</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import userService from '@/api/services/userService';
export default {
  name: 'Login',
  data() {
    return {
      account: '',
      password: ''
    };
  },
  methods: {
    /**
     * 登录
     */
    login() {
      // 判断空账号密码
      if (!this.account || !this.password) {
        this.$message.error('账号或密码不能为空');
        return;
      }

      // 获取当前 URL 的域名
      const domain = window.location.origin;
      // 存储重定向页面地址，如果this.$route.query.redirect不存在，则使用domain
      const redirectUrl = domain + (this.$route.query.redirect || '');

      // 准备请求参数
      const data = {
        account: this.account,
        password: this.password,
      };
      // 调用登录方法
      userService.login(data)
      .then(response => {
        // 重新加载页面，刷新整个浏览器会话
        window.location.href = redirectUrl;
      })
      .catch(error => {
        console.error(error);
      });
    },
    /**
     * 路由到关于页面
     */
    toAbout() {
      this.$router.push('/about');
    },
    /**
     * 路由到注册页面
     */
    toRegister() {
      this.$router.push('/auth/register');
    },
    /**
     * 路由到重置页面
     */
    toReset() {
      this.$router.push('/auth/reset');
    }
  }
};
</script>

<style scoped lang="scss">
@keyframes moveEye {
  0%, 100% { transform: translate(0, 0); }
  20%, 60% { transform: translate(-50%, -50%); }
  40%, 80% { transform: translate(0, 0); }
}
@keyframes fishSwim {
  0% {
    left: 5%;
    transform: scaleX(-1) rotateY(0deg);
  }
  2% {
    transform: scaleX(-1) rotateY(-30deg);
  }
  4% {
    transform: scaleX(-1) rotateY(0deg);
  }
  6% {
    transform: scaleX(-1) rotateY(30deg);
  }
  8% {
    transform: scaleX(-1) rotateY(0deg);
  }
  10% {
    transform: scaleX(-1) rotateY(-30deg);
  }
  12% {
    transform: scaleX(-1) rotateY(0deg);
  }
  14% {
    transform: scaleX(-1) rotateY(30deg);
  }
  16% {
    transform: scaleX(-1) rotateY(0deg);
  }
  18% {
    transform: scaleX(-1) rotateY(-30deg);
  }
  20% {
    transform: scaleX(-1) rotateY(0deg);
  }
  22% {
    transform: scaleX(-1) rotateY(30deg);
  }
  24% {
    transform: scaleX(-1) rotateY(0);
  }
  50% {
    left: 70%;
    transform: scaleX(-1) rotateY(0deg);
  }
  51% {
    transform: scaleX(1) rotateY(0deg);
  }
  55% {
    transform: scaleX(1) rotateY(-30deg);
  }
  60% {
    transform: scaleX(1) rotateY(0deg);
  }
  65% {
    transform: scaleX(1) rotateY(30deg);
  }
  70% {
    transform: scaleX(1) rotateY(0deg);
  }
  75% {
    transform: scaleX(1) rotateY(-30deg);
  }
  80% {
    transform: scaleX(1) rotateY(0deg);
  }
  100% {
    left: 5%;
    transform: scaleX(1) rotateY(0deg);
  }
}
@keyframes fishShake {
  0% {
    transform: translateX(-50%) rotateY(0deg) rotateZ(0deg);
  }
  25% {
    transform: translateX(-50%) rotateY(30deg) rotateZ(30deg);
  }
  50% {
    transform: translateX(-50%) rotateY(0deg) rotateZ(0deg);
  }
  75% {
    transform: translateX(-50%) rotateY(30deg) rotateZ(30deg);
  }
  100% {
    transform: translateX(-50%) rotateY(0deg) rotateZ(0deg);
  }
}
@keyframes waterDown {
  0% {
    top: 5px;
  }
  100% {
    top: 30px;
  }
}
.flip-leave-active .cat-container {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.flip-enter-active .cat-container {
  display: none;
}
#login {
  position: relative;
  .cat-container {
    position: absolute;
    z-index: -1;
    top: -90px;
    left: 50%;
    transform: translateX(-50%);
    backface-visibility: hidden; /* 确保背面内容不可见 */
    .cat {
      display: inline-block;
      font-size: 100px;
    }
    .eye-left, .eye-right {
      position: absolute;
      top: 33%; /* 调整眼睛部分在人物中的位置 */
      width: 5px; /* 调整眼睛的宽度 */
      height: 5px; /* 调整眼睛的高度 */
    }
    .eye-left {
      left: 71%; /* 调整左眼的位置 */
    }
    .eye-right {
      right: 8.8%; /* 调整左眼的位置 */
    }
    .eye-left::before, .eye-right::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #2C3E50; /* 调整眼睛的颜色 */
      border-radius: 50%;
      animation: moveEye 3s ease-in-out infinite;
    }
    .fish-tank {
      position: absolute;
      top: -10px;
      left: 5px;
      transform: translateX(-50%);
      width: 50px;
      height: 15px;
      padding: 10px 15px;
      overflow: hidden;
      background-color: #FFF;
      border-radius: 10px 10px 50px 50px;
      box-shadow: 0 5px 4px rgba(0, 0, 0, 0.2);
      &::before {
        content: "🐟";
        position: absolute;
        z-index: 1;
        animation: fishSwim 10s linear infinite;
      }
      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 5px;
        width: 80px;
        height: 35px;
        background-color: #4BA2FF;
      }
      &:hover {
        cursor: grabbing;
        background-color: #F0F0F0;
        &::before {
          content: "🐟";
          position: absolute;
          left: 50%;
          z-index: 1;
          animation: fishShake 0.5s ease-in-out infinite;
        }
        &::after {
          animation: waterDown 0.5s linear forwards;
        }
      }
    }
    .cat-bubble {
      position: absolute;
      top: 0;
      right: 0;
      &::before {
        content: "";
        position: absolute;
        top: -20px;
        left: 0;
        width: 30px;
        height: 10px;
        border-radius: 50%;
        background-color: #FFF;
      }
      &::after {
        content: "强哥，老墨也想吃鱼了😭";
        position: absolute;
        top: -80px;
        left: 35px;
        transform: translateX(-50%);
        padding: 15px 20px;
        font-size: 12px;
        color: #CCC;
        border-radius: 50%;
        white-space: nowrap;
        background-color: #FFF;
      }
    }
  }

  /* 移动端样式（小于 768px） */
  @media screen and (max-width: 768px) {
    .login-form {
      width: 90% !important;
      margin: 0 auto;
      box-sizing: border-box;
    }
  }

  .login-form {
    width: 300px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;

    .login-title {
      margin-bottom: 30px;
    }

    .account-input {
      margin-bottom: 10px;
    }

    .password-input {
      margin-bottom: 20px;
    }

    .el-button+.el-button {
      margin-left: 0;
    }

    .text-button-group {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
