<template>
  <div id="about">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <!-- 移动端空白填充 -->
          <div class="space"></div>
          <!-- 移动端空白填充 -->
          <div class="space"></div>
          <!-- 头部容器 -->
          <div class="header-container">
            <!-- 返回按钮 -->
            <router-link class="chevron-left" to="/auth/login"><i class="fas fa-chevron-left"></i></router-link>
            <!-- 标题 -->
            <h1 class="title">关于我们</h1>
          </div>
          <!-- 内容容器 -->
          <div class="content-container">
            <!-- 轮播介绍 -->
            <div class="carousel-container">
              <div class="canvas-container">
                <canvas ref="sineWaveCanvas" id="sineWave"></canvas>
              </div>
              <el-carousel class="carousel" :interval="5000" arrow="always" indicator-position="none">
                <el-carousel-item>
                  <div class="item-container">
                    <div class="img-container">
                      <img src="@/assets/img/about/banner/screen.png" />
                    </div>
                    <div class="text-container">
                      <div class="text-title">资源搜索</div>
                      <div class="text-description">拥有非常直观方便的搜索入口，可以帮助您轻松地发现和获取所需的直播资源。不论是央视、卫视、还是地方台，我们都能确保您以最简便的方式找到丰富多样的直播内容。</div>
                      <ul class="text-list">
                        <li><span>实时数据更新</span></li>
                        <li><span>极速查询响应</span></li>
                        <li><span>多维度搜索体验</span></li>
                        <li><span>灵活的搜索选择</span></li>
                        <li><span>高效结果处理</span></li>
                        <li><span>快捷操作体验</span></li>
                      </ul>
                    </div>
                  </div>
                </el-carousel-item>
                <el-carousel-item>
                  <div class="item-container">
                    <div class="img-container">
                      <img src="@/assets/img/about/banner/tv.png" />
                    </div>
                    <div class="text-container">
                      <div class="text-title">海量影视资源</div>
                      <div class="text-description">我们拥有数以万计的直播资源，为您提供最丰富多样的影视内容。无论您想观看央视、卫视还是地方台的节目，我们都能满足您的需求，轻松发现您喜爱的直播内容。</div>
                      <ul class="text-list">
                        <li><span>资源丰富</span></li>
                        <li><span>节目齐全</span></li>
                        <li><span>操作简便</span></li>
                        <li><span>高清画质</span></li>
                        <li><span>快速加载</span></li>
                        <li><span>智能推荐</span></li>
                      </ul>
                    </div>
                  </div>
                </el-carousel-item>
                <el-carousel-item>
                  <div class="item-container">
                    <div class="img-container">
                      <img src="@/assets/img/about/banner/server.png" />
                    </div>
                    <div class="text-container">
                      <div class="text-title">分布式矩阵服务器</div>
                      <div class="text-description">分布式矩阵服务器是为应对大规模网络IP探测而设计的先进解决方案。我们采用独特的分布式服务器矩阵技术，以实时、高速、精确、可靠的方式提供探测服务。</div>
                      <ul class="text-list">
                        <li><span>安全稳定可靠</span></li>
                        <li><span>智能负载均衡</span></li>
                        <li><span>实时任务监控</span></li>
                        <li><span>自适应扩展性</span></li>
                        <li><span>弹性灵活配置</span></li>
                        <li><span>高效的结果输出</span></li>
                      </ul>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
          <!-- 向上滑动提示容器 -->
          <div class="tip-container">
            <!-- 提示文字 -->
            <span class="tip">往上滑动</span>
            <!-- 双箭头向上图标 -->
            <div class="icon-container"><i class="fas fa-angle-double-up"></i></div>
          </div>
          <!-- 移动端空白填充 -->
          <div class="space"></div>
        </div>
        <div class="swiper-slide">
          <!-- 内容容器 -->
          <div class="content-container">
            <!-- 卡片介绍 -->
            <div class="card-container">
              <ul class="card-list">
                <li v-for="(item, index) in card.list" :key="index" 
                    :class="{ 'card-item': true, 'active': index === card.activeIndex }"
                    @mouseenter="setItem($event, index)" @mouseleave="setItem($event, index)">
                  <div class="card">
                    <div class="card-header">
                      <div class="content">
                        <div class="icon-container">
                          <i :class="item.class"></i>
                        </div>
                        <h1>{{ item.title }}</h1>
                        <span class="description">{{ item.description }}</span>
                      </div>
                    </div>
                    <div class="card-content content-list-container">
                      <ul class="content-list">
                        <li v-for="(item, index) in item.content" :key="index">{{ item }}</li>
                      </ul>
                    </div>
                    <div class="card-content open">
                      <el-image :src="item.demo" :preview-src-list="[item.demo]" fit="cover">
                        <template slot="placeholder">
                          <!-- 加载状态 -->
                          <el-skeleton animated>
                            <template slot="template">
                              <el-skeleton-item variant="image" />
                            </template>
                          </el-skeleton>
                        </template>
                      </el-image>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <!-- 移动端空白填充 -->
            <div class="space"></div>
          </div>
        </div>
        <div class="swiper-slide">
          <!-- 内容容器 -->
          <div class="content-container">
            <!-- 访客统计 -->
            <div class="visitor-count-container">
              <h2 class="title">访客统计</h2>
              <img src="https://access-counter.isus.cc/api/counter?name=isus&theme=002" />
            </div>
            <!-- 移动端空白填充 -->
            <div class="space"></div>
            <!-- 移动端空白填充 -->
            <div class="space"></div>
          </div>
        </div>
        <div class="swiper-slide">
          <!-- 内容容器 -->
          <div class="content-container">
            <!-- 图标介绍 -->
            <div class="icon-list-container" ref="iconListContainer">
              <h2 class="title">实时嗅探</h2>
              <div class="description">全王探测是一款专为实时发现互联网上所有公开非加密的 HTTP 资源而设计的通用型 IP 探测器。它可以大批量捕获互联网上常见的资源类型，比如漏洞扫描，去抓取互联网中拥有特定漏洞的靶机，也可以对直播源进行超大范围探测，又或者 Github 上的众多开源项目，比如，获取大批量优选IP，还比如查找全球免密socks代理，甚至能解决CDN溯源问题等等。总之，它是一个针对 HTTP 特征而设计的超大规模 IP 探测工具。</div>
              <ul class="icon-list">
                <li class="icon-item" v-for="(item, index) in icon.list" :key="index">
                  <span class="icon"><i :class="item.class"></i></span>
                  <span class="num">{{ item.num }}+</span>
                  <span class="name">{{ item.name }}</span>
                </li>
              </ul>
            </div>
            <!-- 移动端空白填充 -->
            <div class="space"></div>
          </div>
        </div>
        <div class="swiper-slide">
          <!-- 内容容器 -->
          <div class="content-container">
            <!-- 图片介绍 -->
            <div class="img-list-container">
              <div class="scroll-container">
                <div class="img-list">
                  <el-image v-for="(url, index) in img.list" :key="index" :src="url" :preview-src-list="img.list"></el-image>
                </div>
              </div>
            </div>
            <!-- 移动端空白填充 -->
            <div class="space"></div>
          </div>
        </div>
        <div class="swiper-slide">
          <!-- 内容容器 -->
          <div class="content-container">
            <!-- 常见的问答 -->
            <div class="qa-list-container">
              <h2 class="title">常见问答</h2>
              <el-collapse v-model="qa.activeName" accordion>
                <el-collapse-item title="1、这个网站是做什么的？" name="1">
                  <p>&emsp;&emsp;在互联网世界中，HTTP协议是一种常见的网络协议。而我们网站的核心，就是专门为HTTP而设计，它是一款分布式的算力共享探测器，可以通过无限堆叠服务器算力，从而达到惊人的探测速度。</p>
                  <p>&emsp;&emsp;我们所熟知的百度搜索引擎，它的原理就是利用高效的爬虫技术，针对互联网的80端口的网站资产进行采集，然后整理归纳存储，再提供给用户查询。而我们的网站和百度搜索引擎背后的爬虫一样，但是，我们不止是针对80端口的资产，而是面向互联网的全端口资产，进行实时的特征采集。</p>
                </el-collapse-item>
                <el-collapse-item title="2、为什么需要实时采集数据呢？" name="2">
                  <p>&emsp;&emsp;IPV4是有限的，所以运营商推出了一种动态IP的解决方案，99%的家庭带宽都是动态IP，动态IP也遍布整个世界，它的特点是，带宽小，每日更新IP。如果不进行实时数据采集，是无法捕获这类资产的，除此之外，很多数据也需要最新的，否则将会没有意义。所以，实时采集是必要的。</p>
                </el-collapse-item>
                <el-collapse-item title="3、为什么不用FOFA、ZoomEye等这类网络测绘空间呢？" name="3">
                  <p>&emsp;&emsp;当然得用，但是网络测绘空间毕竟只是一张网络地图，它存在诸多问题，比如，更新速度慢，漏扫严重，无效多、分类不直观，使用不方便、收费昂贵等问题，针对以上种种问题，所以推出了我们这种有针对性的，精细化探测的平民级解决方案。</p>
                </el-collapse-item>
                <el-collapse-item title="4、为什么选择我们？" name="4">
                  <p>&emsp;&emsp;1）我们是世界上唯一的实时探测器，拥有大量测绘空间网站没有的珍贵数据资产。</p>
                  <p>&emsp;&emsp;2）你贡献了自己的微小算力，你就可以调度所有人贡献的庞大算力，为你一人服务。</p>
                  <p>&emsp;&emsp;3）网站摒弃传统的收费服务模式，采用先进的算力共享模式，致力于打造一个“以鸡会友”的平台。</p>
                  <p>&emsp;&emsp;4）贡献自己的微渺力量，照亮你我，加入我们，可让你的小鸡发光发热。</p>
                </el-collapse-item>
                <el-collapse-item title="5、如何注册账号？" name="5">
                  <p>&emsp;&emsp;请访问我们的<router-link to="/auth/register">注册页面</router-link>，并填写所需的信息，包括用户名、QQ邮箱和密码。</p>
                </el-collapse-item>
                <el-collapse-item title="6、为什么使用网站的某些功能时会提示权限不足？" name="6">
                  <p>&emsp;&emsp;个别功能比较强大，会消耗很多的算力，所以它们都是保留给网站的会员用户使用的，他们既是网站的捐助者，也是网站能持续发展的动力来源。</p>
                </el-collapse-item>
                <el-collapse-item title="7、如何成为网站的会员？" name="7">
                  <p>&emsp;&emsp;只要对网站有一定的贡献，那么就会成为会员用户，以下给出了3种贡献方式：</p>
                  <p>&emsp;&emsp;&emsp;&emsp;1）出力：能给网站持续提供技术支持，要有参与过开源项目的经验。</p>
                  <p>&emsp;&emsp;&emsp;&emsp;2）捐机器：</p>
                  <p>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;最低配置要求：<br/>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;a）足够稳定。<br/>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;b）国内的云服务器。<br/>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;c）2G内存，服务运行期间，大约占用1-1.5G内存。<br/>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;d）带宽最好有5Mbps以上，服务运行期间，大约会占用3Mbps左右（375KB/s）。<br/><span style="color: red;">&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;重点强调：如果服务器超出了最低配置，那么服务器仍然可以继续正常使用。</span><br/><span style="color: grey;">&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;友情建议：可购买<a href="https://cn.aliyun.com/benefit" target="_blank">阿里云</a>，<a href="https://cloud.tencent.com/act" target="_blank">腾讯云</a>等大厂的云服务提供商，至少购买1年起，在校大学生还能免费白嫖阿里云，相当于无偿加入。</span></p>
                  <p>&emsp;&emsp;&emsp;&emsp;3）捐款：既不能出力，又没有可用机器贡献，那也可以选择捐款，款项和正常购买同配置服务器差不多。</p>
                </el-collapse-item>
                <el-collapse-item title="8、我成为了会员，有什么不一样？" name="8">
                  <p>&emsp;&emsp;首先，有很多的高级功能，是专门为会员用户量身打造的，比如高级解析、IP自动追踪、智能分流订阅等等。</p>
                  <p>&emsp;&emsp;其次，如果你喜欢折腾直播源，那用“不愁直播源”形容我们网站是非常合适的。在结果面板中，有数不尽的直播源，可以不用探测就有用不完的直播源。有熟知的酒店源、组播源，也有没听过的酒店源，还有比较罕见的祖国同胞源等等。</p>
                  <p>&emsp;&emsp;除了用不完的直播源以外，当你还在睡梦中时，我们的探测器仍然在工作，当你一觉醒来，就又有一大批的源已为你采集完毕，这也是一种相当奇妙的体验。</p>
                  <p>&emsp;&emsp;最重要的是，我们的算力共享探测器是通用型的IP探测器，它不光可以探测直播源，而且也可以探测任何其他HTTP资源，这里列举一些常见用途：漏洞扫描、节点池、CF优选IP、免密SOCKS代理、CDN溯源等等，其余的用途，可以充分发挥你的想象力。</p>
                </el-collapse-item>
                <el-collapse-item title="9、我不贡献，能白嫖到什么服务？" name="9">
                  <p>&emsp;&emsp;可以无偿享受到一个没有杂乱广告的纯净直播源世界，系统也在不断拓展优化，只为创造一个拥有良好体验的同时，又能持续免费无偿的提供优质服务的多方共赢局面，比如，资源搜索、节目解析、chatGPT、AI绘画、AI音乐等等。</p>
                </el-collapse-item>
                <el-collapse-item title="10、目前有多少算力？探测速度有多快？和我自己探测差距大吗？" name="10">
                  <p>&emsp;&emsp;目前已有几十台小鸡，按照一个进程占用50M左右，可同时并发500+进程不止，可以做到全国（包括港澳台，大约有4个多亿的IP量），大约2小时左右完成探测。如果嫌这个速度还不够快，那可以直接复用旧数据，做到极致速度——10分钟左右探测完4个亿的IP量。相比于单机去探测，这种分布式架构，不光是速度快了，而且它的精准程度是100%，更重要的是，它可以通过无限堆叠算力，进而达到一个惊人的速度。</p>
                </el-collapse-item>
              </el-collapse>
            </div>
            <!-- 尾部容器 -->
            <div class="footer-container">
              <div class="footer-section">
                <div class="copyright">© 2024 全王探测 All rights reserved.</div>
              </div>
              <div class="footer-section">
                <div class="social-icons">
                  <a class="icon-link" href="https://github.com/Subdue0/ip-scanner" title="GitHub项目地址" target="_blank"><i class="fab fa-github"></i></a>
                  <a class="icon-link" href="https://t.me/+Cr9uNkqY-uI5ZDRl" title="加入TG群" target="_blank"><i class="fab fa-telegram"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css'
export default {
  name: 'About',
  data() {
    return {
      card: {
        activeIndex: null, // 当前激活的卡片索引
        list: [
          {
            class: 'fas fa-search',
            title: '核心探测',
            description: '添加任务',
            content: ['IP前缀探测', '分布式探测', '区域性探测', '单端口探测', '全端口探测'],
            demo: require('@/assets/img/about/card/demo_add_task.gif'),
          },
          {
            class: 'fas fa-cogs',
            title: '管理面板',
            description: '搜索解析',
            content: ['华丽UI设计', '移动端适配', '功能强大齐全', '操作舒适便捷', '高效响应速度'],
            demo: require('@/assets/img/about/card/demo_search_parse.gif'),
          },
          {
            class: 'fas fa-chart-bar',
            title: '结果面板',
            description: '核心功能',
            content: ['白天/夜间模式', '资源搜索', '打包下载', '详细分类', '使用说明'],
            demo: require('@/assets/img/about/card/demo_result.gif'),
          },
          {
            class: 'fas fa-star',
            title: '高级功能',
            description: '特点特色',
            content: ['IP自动追踪', '定时巡航探测', '智能失效检测', '智能分流订阅', 'Q群智能机器人'],
            demo: require('@/assets/img/about/card/demo_coming_soon.jpg'),
          }
        ],
      },
      icon: {
        isAnimating: false, // 标志，用于防止动画重新触发
        list: [
        {
          class: 'fas fa-film', // 节目源数量
          num: '99999',
          name: '节目源',
        },
        {
          class: 'fas fa-globe', // IP源数量
          num: '9999',
          name: 'IP源',
        },
        {
          class: 'fas fa-hotel', // 酒店源
          num: '3000',
          name: '酒店源',
        },
        {
          class: 'fas fa-layer-group', // 组播源
          num: '2000',
          name: '组播源',
        },
        {
          class: 'fas fa-wifi', // 地波源
          num: '500',
          name: '地波源',
        },
        {
          class: 'fas fa-tv', // 开源电视流媒体服务器
          num: '300',
          name: '开源服务',
        },
      ]},
      img: {
        list: [
          require('@/assets/img/about/img_list/channel_1.jpg'),
          require('@/assets/img/about/img_list/channel_2.jpg'),
          require('@/assets/img/about/img_list/channel_3.jpg'),
          require('@/assets/img/about/img_list/channel_4.jpg'),
          require('@/assets/img/about/img_list/channel_5.jpg'),
          require('@/assets/img/about/img_list/channel_6.jpg'),
        ]
      },
      qa: {
        activeName: '0',
      }
    };
  },
  mounted() {
    // 改变this指向
    const that = this;
    const swiper = new Swiper('.swiper-container', {
      // 300px 间距
      spaceBetween: 300,
      // 垂直方向
      direction: 'vertical',
      // 每次显示 1 个滑块
      slidesPerView: 1,
      // 键盘控制开启
      keyboard: true,
      // 鼠标滚轮控制开启
      mousewheel: true,
      // 鼠标变为抓取手柄样式
      grabCursor: true,
      on: {
        init: function () {
          // 初始化时启动正弦波动画
          that.animateSineWave();
        },
        slideChange: function () {
          switch (this.activeIndex) {
            case 0:
              // 启动正弦波动画
              that.animateSineWave();
              break;
            case 3:
              // 延时2秒启动数字缓动动画
              setTimeout(() => {
                that.triggerAnimations();
              }, 2000);
              break;
            case 5:
              // 初始化数据
              that.qa.activeName = '0';
              break;
            default:
              // 默认情况下的代码
          }
        },
      },
    });
  },
  methods: {
    // 触发数字缓动动画
    triggerAnimations() {
      if (!this.icon.isAnimating) {
        // 标记动画开始
        this.icon.isAnimating = true;
        const totalCount = this.icon.list.length;
        // 完成动画的数量
        let completedCount = 0;

        this.icon.list.forEach((item) => {
          // 结束值
          const endValue = parseInt(item.num, 10);
          // 执行数字动画
          this.animateNumber(item, 'num', 0, endValue, () => {
            completedCount++;
            // 当所有动画完成后，标记动画结束
            if (completedCount === totalCount) {
              this.icon.isAnimating = false;
            }
          });
        });
      }
    },
    // 数字缓动动画
    animateNumber(target, key, startValue, endValue, callback, duration = 3000) {
      // 更新间隔
      const updateInterval = 50;
      // 缓动函数
      const customEase = t => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
      // 当前时间
      let currentTime = 0;
      const interval = setInterval(() => {
        currentTime += updateInterval;
        // 计算进度
        const progress = Math.min(currentTime / duration, 1);
        // 应用缓动函数
        const easedProgress = customEase(progress);
        // 计算当前值
        const easedValue = startValue + (endValue - startValue) * easedProgress;
        // 更新值
        this.$set(target, key, Math.round(easedValue));

        if (currentTime >= duration) {
          // 清除定时器
          clearInterval(interval);
          // 调用回调函数
          if (callback) callback();
        }
      }, updateInterval);
    },
    setItem(e, index) {
      if (e.type === 'mouseenter') {
        // 鼠标指针进入时，设置当前激活的卡片索引为当前索引
        this.card.activeIndex = index;
      } else if (e.type === 'mouseleave') {
        // 鼠标指针离开时，设置当前激活的卡片索引为空
        this.card.activeIndex = null;
      }
    },
    // 绘制正弦波动画
    drawSineWave(canvas, amplitude, frequency, offset, lineConfigs, lineWidth) {
      const ctx = canvas.getContext('2d');
      const width = canvas.width;
      const height = canvas.height;
      ctx.clearRect(0, 0, width, height);

      for (let i = 0; i < lineConfigs.length; i++) {
        const { color, speed } = lineConfigs[i];
        ctx.strokeStyle = color;

        const yStart = ((i + 1) * height) / (lineConfigs.length + 1);
        const yEnd = ((i + 1) * height) / (lineConfigs.length + 1);

        ctx.beginPath();
        ctx.moveTo(0, yStart);

        for (let x = 0; x < width; x++) {
          const y = (yStart + yEnd) / 2 + amplitude * Math.sin((x / frequency) + offset * speed);
          ctx.lineTo(x, y);
        }

        ctx.lineWidth = lineWidth;
        ctx.stroke();
      }
    },
    animateSineWave() {
      const canvas = this.$refs.sineWaveCanvas;
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;

      const amplitude = 50;
      const frequency = 100;
      let offset = 0;
      const lineWidth = 1;
      const lineConfigs = [
        { color: 'rgba(255, 255, 255, .6)', speed: 1 },
        { color: 'rgba(255, 255, 255, .6)', speed: 1.2 },
        { color: 'rgba(255, 255, 255, .6)', speed: 1.5 }
      ];

      const animate = () => {
        this.drawSineWave(canvas, amplitude, frequency, offset, lineConfigs, lineWidth);
        offset += 0.05;
        requestAnimationFrame(animate);
      };

      animate();
    },
  }
};
</script>

<style scoped lang="scss">
@keyframes fadeOutUp {
  0% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}
@keyframes rollIn {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    visibility: visible;
  }
  100% {
    opacity: 1;
    transform: none;
    visibility: visible;
  }
}
@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
    visibility: visible;
  }
  50% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@keyframes bounceIn {
  0%, 20%, 40%, 60%, 80%, 100% {
    transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    visibility: visible;
  }
  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }
  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    transform: scale3d(.9, .9, .9);
  }
  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    transform: scale3d(.97, .97, .97);
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
@keyframes slideDown {
  from {
    transform: translateY(-100px);
    visibility: visible;
  }
  to {
    transform: translateY(0);
    visibility: visible;
  }
}
@keyframes slideUp {
  from {
    transform: translateY(100px);
    visibility: visible;
  }
  to {
    transform: translateY(0);
    visibility: visible;
  }
}
@keyframes lightSpeedIn {
  0% {
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0;
    visibility: visible;
  }
  60% {
    transform: skewX(20deg);
    opacity: 1;
    visibility: visible;
  }
  80% {
    transform: skewX(-5deg);
    opacity: 1;
    visibility: visible;
  }
  100% {
    transform: none;
    opacity: 1;
    visibility: visible;
  }
}
@keyframes swing {
  20% {
    transform: rotate(15deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  60% {
    transform: rotate(5deg);
  }
  80% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 0.5;
  }
  100% {
    width: 500px;
    height: 500px;
    opacity: 0;
  }
}
#about ::v-deep {
  /* 窄桌面端样式（小于 1200px） */
  @media screen and (max-width: 1200px) {
    .carousel-container {
      .carousel {
        .el-carousel__container {
          height: 550px;
          .el-carousel__item {
            .item-container {
              display: block !important;
              .img-container {
                width: 50%;
                margin: 0 auto;
              }
            }
          }
        }
      }
    }
    .img-list-container {
      .scroll-container {
        width: 600px !important;
        height: 600px !important;
        .img-list {
          left: 180px !important;
          top: 50px !important;
          width: 220px !important;
          height: 460px !important;
          border-radius: 20px !important;
        }
      }
    }
  }
  /* 移动端样式（小于 768px） */
  @media screen and (max-width: 768px) {
    .header-container {
      padding: 0 !important;
    }
    .carousel-container {
      .carousel {
        width: 100% !important;
        padding: 0 !important;
        .el-carousel__container {
          .el-carousel__item {
            .item-container {
              display: block !important;
              padding: 0 50px !important;
              .img-container {
                width: 80%;
                margin: 0 auto;
              }
              .text-container {
                padding: 30px 10px;
                .text-list {
                  padding: 0;
                  li {
                    span {
                      padding: 5px 10px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .card-container {
      width: 90% !important;
      margin: 0 auto;
      .card-list {
        height: 700px !important;
        .card-item {
          height: 650px !important;
          &.active {
            height: 700px !important;
          }
        }
      }
    }
    .visitor-count-container {
      img {
        width: 90%;
        margin: 0 auto;
      }
    }
    .icon-list-container {
      width: 90%;
      margin: 0 auto;
      .icon-list {
        .icon-item {
          margin: 0 !important;
          .icon {
            font-size: 30px !important;
          }
          .num {
            font-size: 14px !important;
          }
          .name {
            font-size: 14px !important;
          }
        }
      }
    }
    .img-list-container {
      .scroll-container {
        width: 300px !important;
        height: 300px !important;
        .img-list {
          left: 90px !important;
          top: 25px !important;
          width: 110px !important;
          height: 230px !important;
          border-radius: 15px !important;
        }
      }
    }
    .qa-list-container {
      .el-collapse {
        .el-collapse-item {
          &:nth-last-child(4) {
            .el-collapse-item__content {
              white-space: nowrap;
              overflow-x: scroll;
            }
          }
          .el-collapse-item__header {
            line-height: 1.5;
            text-align: left;
          }
        }
      }
    }
  }

  .swiper-container {
    width: 100%;
    height: 100vh;
    /* 移动端空白填充样式（小于 768px） */
    @media screen and (max-width: 768px) {
      .swiper-slide {
        .space {
          height: 50px;
        }
      }
    }
    .swiper-slide {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      &.swiper-slide-active {
        .tip-container {
          visibility: visible;
          transition: visibility 0s 1.5s;
        }
        .content-container {
          .carousel-container,
          .card-container,
          .img-list-container {
            animation: slideUp 1s ease-in-out forwards;
          }
        }

        .icon-list-container {
          .title {
            animation: rollIn 1s ease-in-out forwards;
          }
          .description {
            overflow: hidden;
            margin: 0 auto;
            letter-spacing: .15em;
            animation: zoomIn 1s 1s ease-in-out forwards;
          }
          .icon-list {
            animation: bounceIn 1s 2s ease-in-out forwards;
          }
        }

        .visitor-count-container {
          .title {
            animation: rollIn 1s ease-in-out forwards;
          }
          img {
            animation: lightSpeedIn 1s 1s ease-in-out forwards;
          }
        }

        .qa-list-container {
          .title {
            animation: rollIn 1s ease-in-out forwards;
          }
          .el-collapse {
            animation: slideUp 1s 1s ease-in-out forwards;
          }
        }

        .footer-container {
          animation: lightSpeedIn 1s 2s ease-in-out forwards;
        }
      }
      &:not(:first-child) {
        .content-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
      &:first-child {
        &:hover {
          .tip-container {
            visibility: visible;
            transition: visibility 0s 1.5s;
          }
        }
      }
      .header-container {
        padding: 20px 0;
        text-align: center;
        animation: slideDown 1s ease-in-out forwards;
        .chevron-left {
          font-size: 30px;
          color: #1E9FFF;
          cursor: pointer;
        }
        .title {
          font-family: '方正王献之小楷 简';
          font-size: 35px;
          font-weight: bold;
          color: #1E9FFF;
        }
      }
      .content-container {
        flex: 1;
        width: 100%;
      }
      .tip-container {
        visibility: hidden;
        animation: fadeOutUp 1.5s ease-in-out infinite;
        .tip {
          color: #FFF;
        }
        .icon-container {
          font-size: 30px;
          color: #FFF;
        }
      }
    }
  }

  .carousel-container {
    position: relative;
    .canvas-container {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 200px;
      #sineWave {
        width: 100%;
        height: 100%;
      }
    }
    .carousel {
      width: 80%;
      margin: 0 auto;
      .el-carousel__item {
        display: flex;
        justify-content: space-around;
        align-items: center;
        
        .item-container {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 100px;
          .img-container {
            display: flex;
            justify-content: center;
            img {
              width: 100%;
              height: auto;
              pointer-events: none;
            }
          }
          .text-container {
            text-align: left;
            color: #333;
            padding: 30px;
            .text-title {
              margin-bottom: 10px;
              font-size: 22px;
              font-weight: bold;
              text-align: center;
            }
            .text-description {
              font-size: 16px;
              line-height: 2;
              margin-bottom: 20px;
            }
            .text-list {
              font-size: 14px;
              list-style-type: none;
              &::before {
                content: "";
                display: table;
                clear: both;
              }
              &::after {
                content: "";
                display: table;
                clear: both;
              }
              li {
                width: 50%;
                float: left;
                margin-bottom: 10px;
                span {
                  padding: 5px 15px;
                  &::before {
                    display: inline-block;
                    content: "";
                    width: 4px;
                    height: 4px;
                    vertical-align: middle;
                    border-radius: 50%;
                    background: #333;
                    margin-right: 5px;
                  }
                  &:hover {
                    border-radius: 99px;
                    background-color: #CCC;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .card-container {
    position: relative;
    width: 80%;
    margin: 0 auto;
    &::before {
      content: '';
      position: absolute;
      z-index: 5;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      background-color: #FFF;
      pointer-events: none;
      animation: ripple 2s linear infinite;
    }
    .card-list {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      margin: 0;
      padding: 0;
      list-style-type: none;
      &::before {
        content: "";
        display: table;
        clear: both;
      }
      &::after {
        content: "";
        display: table;
        clear: both;
      }
      .card-item {
        position: relative;
        z-index: 4;
        float: left;
        width: 18%;
        height: 500px;
        overflow: hidden;
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1), z-index 0s 0.12s;
        &:nth-child(n) {
          background-color: #F0F0F0;
        }
        &:nth-child(2n) {
          background-color: #F8F8F8;
        }
        &.active {
          z-index: 6;
          box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
          width: 46%;
          height: 550px;
          .card {
            .card-header {
              background-color: #3db1ea;
              border: none;
            }
            .card-content {
              &.content-list-container {
                transition: all 0.12s cubic-bezier(0.4, 0, 0.2, 1) 0s;
                z-index: 2;
                opacity: 0;
              }
              &.open {
                transition: all 0.12s cubic-bezier(0.4, 0, 0.2, 1) 0.18s;
                z-index: 3;
                opacity: 1;
                .el-image {
                  width: 100%;
                  height: 405px;
                  .el-skeleton.is-animated {
                    .el-skeleton__item.el-skeleton__image {
                      width: 100%;
                      height: 405px;
                    }
                  }
                }
                img {
                  width: 100%;
                  height: 405px;
                }
              }
            }
          }
        }
        .card {
          transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1), z-index 0s 0.12s;
          width: 100%;
          height: 100%;
          text-align: center;
          .card-header {
            transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1), z-index 0s 0.12s;
            height: 145px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            z-index: 1;
            font-size: 14px;
            border-left: 1px solid #3285C0;
            background-color: #1B77B9;
            .content {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              width: 100%;
              .icon-container {
                display: inline-block;
                width: 40px;
                height: 40px;
                line-height: 40px;
                i {
                  display: inline-block;
                  font-size: 20px;
                  color: #CCC;
                }
              }

              h1 {
                margin: 10px 0 10px 0;
                font-size: 22px;
                color: #FFF;
              }

              .description {
                font-size: 14px;
                color: #CCC;
              }
            }
          }
          .card-content {
            position: absolute;
            top: 145px;
            width: 100%;
            height: 355px;
            line-height: 2;
            &.content-list-container {
              transition: all 0.12s cubic-bezier(0.4, 0, 0.2, 1) 0.18s;
              z-index: 3;
              opacity: 1;
              .content-list {
                height: 100%;
                margin: 0;
                padding: 0;
                list-style-type: none;
                li {
                  margin: 30px 0;
                }
              }
            }
            &.open {
              transition: all 0.12s cubic-bezier(0.4, 0, 0.2, 1) 0s;
              z-index: 2;
              opacity: 0;
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }

  .visitor-count-container {
    width: 80%;
    margin: 0 auto;
    .title {
      font-size: 22px;
      visibility: hidden;
    }
    img {
      visibility: hidden;
    }
  }

  .icon-list-container {
    width: 80%;
    margin: 0 auto;
    .title {
      font-size: 22px;
      visibility: hidden;
    }
    .description {
      text-align: left;
      line-height: 2;
      visibility: hidden;
    }
    .icon-list {
      margin: 0;
      padding: 0;
      list-style-type: none;
      visibility: hidden;
      &::before {
        content: "";
        display: table;
        clear: both;
      }
      &::after {
        content: "";
        display: table;
        clear: both;
      }
      .icon-item {
        float: left;
        display: flex;
        flex-direction: column;
        width: 33.3%;
        margin: 20px 0;
        &:hover {
          .icon {
            color: #4BA2FF;
            animation: swing 2s ease-in-out infinite;
          }
          .num {
            color: #4BA2FF;
          }
          .name {
            color: #4BA2FF;
          }
        }
        .icon {
          margin: 10px 0;
          font-size: 50px;
        }
        .num {
          margin: 10px 0;
          font-size: 20px;
          font-weight: bold;
        }
        .name {
          margin: 10px 0;
          font-size: 20px;
          font-weight: bold;
        }
      }
    }
  }

  .img-list-container {
    width: 80%;
    margin: 0 auto;
    .scroll-container {
      position: relative;
      width: 800px;
      height: 800px;
      margin: 0 auto;
      background-image: url('@/assets/img/about/img_list/phone.png');
      background-size: 100% 100%;
      overflow: auto;
      .img-list {
        position: absolute;
        top: 65px;
        left: 240px;
        width: calc(100% - 505px);
        height: calc(100% - 180px);
        border-radius: 30px;
        overflow: hidden;
        /* 隐藏滚动条 */
        &::-webkit-scrollbar {
          display: none;
        }
        .el-image {
          overflow: unset;
        }
      }
    }
  }

  .qa-list-container {
    width: 80%;
    margin: 0 auto;
    .title {
      font-size: 22px;
      visibility: hidden;
    }
    .el-collapse {
      visibility: hidden;
      .el-collapse-item {
        .el-collapse-item__header {
          font-size: 14px;
          background-color: transparent;
          &:hover {
            color: #4BA2FF;
          }
        }
        .el-collapse-item__wrap {
          text-align: left;
          background-color: transparent;
        }
      }
    }
  }
  .footer-container {
    padding: 50px 0;
    visibility: hidden;
    .footer-section {
      .copyright {
        color: rgba(30, 159, 255, 0.5);
      }
      .social-icons {
        padding: 10px;
        .icon-link {
          &:not(:last-child) {
            margin-right: 30px;
          }
          i {
            color: rgba(30, 159, 255, 0.5);
          }
        }
      }
    }
  }
}
</style>
