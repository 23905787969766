<template>
  <div class="parse-container">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" v-loading="loading" element-loading-text="加载中，请稍后..." @submit.native.prevent="parse">
      <el-form-item prop="src">
        <div class="parse-item">
          <el-input class="parse-input" v-model="ruleForm.src" placeholder="IP地址/域名:端口号" @keyup.enter.native="parse"></el-input>
          <el-button class="parse-button" icon="el-icon-link" type="primary" v-throttle="parse">解析</el-button>
        </div>
      </el-form-item>
    </el-form>

    <!-- 解析类型选择 -->
    <div class="dialog-container">
      <el-dialog title="选择类型" :visible="optDialog === 'type'" width="30%" :before-close="closeOptDialog"
        :close-on-click-modal="false">
        <el-popover
          placement="top-start"
          title="功能说明"
          width="200"
          trigger="click">
          <el-button :class="`radio${activeIndex === 0? ' active' : ''}`" slot="reference" @click="selectType(0)">普通解析（速度快）</el-button>
          <slot name="content">
            <div>1、包含节目的名称、地址</div>
          </slot>
        </el-popover>
        <el-popover
          placement="top-start"
          title="功能说明"
          width="200"
          trigger="click"
          content="">
          <el-button :class="`radio${activeIndex === 1? ' active' : ''}`" slot="reference" @click="selectType(1)">高级解析（耗时长）</el-button>
          <slot name="content">
            <div>1、包含节目的名称、地址、分辨率、帧率等</div>
            <div>2、检测IP源是否存在域名</div>
            <div>3、检测所有节目源是否有效</div>
            <div>4、解析组播地址时会携带完整的组播ID，包括央卫视、地方台</div>
          </slot>
        </el-popover>
        <span slot="footer" class="dialog-footer">
          <el-button @click="optDialog = ''">我再想想</el-button>
          <el-button type="primary" v-throttle="typeConfirmed">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import router from '@/router';
export default {
  name: 'Parse',
  data() {
    var validateSrc = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入源地址，格式为IP地址/域名:端口号'));
      } else {
        // 正则表达式用于匹配IP地址/域名:端口号的格式
        const pattern = /^((?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}|\b(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\b):([0-9]+)$/;
        if (!pattern.test(value)) {
          callback(new Error('源地址格式不正确，正确格式应为IP地址/域名:端口号'));
        } else {
          // 提取端口号并转换为数字进行范围验证
          const port = parseInt(value.split(':')[1], 10);
          if (port < 0 || port > 65535) {
            callback(new Error('端口范围应在0到65535之间'));
          } else {
            callback();
          }
        }
      }
    };
    return {
      loading: false, // 控制页面的加载状态
      role: {
        name: 'guest',
        text: '游客',
        level: 1
      }, // 用户默认角色
      ruleForm: {
        src: '',
      },
      rules: {
        src: [
          { required: true, validator: validateSrc, trigger: 'blur' }
        ],
      },
      optDialog: '',
      activeIndex: 0,
    };
  },
  mounted() {
    // 读取角色信息，若不存在则使用默认角色
    this.role = JSON.parse(localStorage.getItem('role')) || { name: 'guest', text: '游客', level: 1 };
  },
  methods: {
    // 跳转到结果页面
    toResult(src, type) {
      // 跳转到路径为 /result/parse 的路由，并传递 src 参数
      router.push({ path: '/result/parse', query: { src, type } });
    },
    // 关闭对话框
    closeOptDialog() {
      // 关闭窗口
      this.optDialog = '';
    },
    // 选择类型
    selectType(type) {
      if (type === 0) {
        this.activeIndex = 0;
      } else if (type === 1) {
        this.activeIndex = 1;
      }
    },
    // 确定类型
    typeConfirmed() {
      // 关闭窗口
      this.optDialog = '';
      // 初始化类型变量
      let type = '';
      // 类型为基本解析
      if (this.activeIndex === 0) {
        type = 'basic';
      // 类型为高级解析
      } else if (this.activeIndex === 1) {
        type = 'plus';
        // 权限不足提示
        if (this.role.level <= 10) {
          this.$message({
            message: '您无权执行此操作，请联系管理员提升权限',
            type: 'error',
            duration: 5000
          });
          return;
        }
      }
      // 调用toResult方法，传递视频源和类型
      this.toResult(this.ruleForm.src, type);
    },
    // 解析
    parse() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // 设置操作对话框类型为类型
          this.optDialog = 'type';
        }
      });
    },
  },
}
</script>

<style scoped lang="scss">
.parse-container ::v-deep {
  width: 300px;
  margin: 0 auto;
  margin-top: 200px;

  /* 移动端样式（小于 1200px） */
  @media screen and (max-width: 1200px) {
    .dialog-container {
      .el-dialog {
        width: 90% !important;
      }
    }
  }

  /* PC端样式（大于等于 1200px） */
  @media screen and (min-width: 1200px) {

  }

  .dialog-container {
      .el-dialog {
        .el-dialog__body {
          .radio {
            margin-left: 10px;
            padding: 12px 10px;
            font-size: 12px;
            &:hover {
              color: #606266;
              border-color: 1px solid #DCDFE6;
              background-color: #FFF;
            }
            &.active {
              color: #409EFF;
              border-color: #c6e2ff;
              background-color: #ecf5ff;
            }
          }
        }

      }
    }

  .parse-item {
    display: flex;
    justify-content: center;
    align-items: center;

    .parse-input {
      line-height: 1.15;
      .el-input__inner {
        font-size: 16px;
        border: 1px solid #BBB;
        border-radius: 20px 0 0 20px;
      }
    }

    .parse-button {
      width: 100px;
      padding: 10px;
      line-height: 1.15;
      font-size: 16px;
      cursor: pointer;
      border: 1px solid #409EFF;
      border-radius: 0 20px 20px 0;
    }
  }

}
</style>
