import { apiInstance, API_URLS } from '@/api';

const taskService = {
  // 获取任务列表
  listTasks() {
    return apiInstance.get(API_URLS.LIST_TASKS, {
      headers: {
        requiresAuth: true
      }
    });
  },

  // 获取当前任务
  getCurrentTask() {
    return apiInstance.get(API_URLS.CURRENT_TASKS, {
      headers: {
        requiresAuth: true
      }
    });
  },

  // 获取任务省份
  getTaskProvinces() {
    return apiInstance.get(API_URLS.TASK_PROVINCES, {
      headers: {
        requiresAuth: true
      }
    });
  },
  // 获取任务城市
  getTaskCities(data) {
    return apiInstance.get(API_URLS.TASK_CITIES, {
      params: data,
      headers: {
        requiresAuth: true
      }
    });
  },

  // 添加新任务
  addNewTask(data) {
    return apiInstance.post(API_URLS.SAVE_TASK, data, {
      headers: {
        requiresAuth: true
      }
    });
  },

  // 管理任务
  manageTask(data) {
    return apiInstance.post(API_URLS.MANAGE_TASK, data, {
      headers: {
        requiresAuth: true
      }
    });
  },

  // 编辑任务
  editTask(data) {
    return apiInstance.post(API_URLS.UPDATE_TASK, data, {
      headers: {
        requiresAuth: true
      }
    });
  },

  // 删除任务
  deleteTask(data) {
    return apiInstance.post(API_URLS.DELETE_TASK, data, {
      headers: {
        requiresAuth: true
      }
    });
  },
};

export default taskService;
