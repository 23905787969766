import MusicPlayer from '@/assets/js/musicPlayer.js';

export default {
  install(Vue) {
    // 将音乐播放器实例挂载到 Vue 实例的原型链上，使得在组件中可以通过 this.$musicPlayer 访问
    Vue.prototype.$musicPlayer = new MusicPlayer({
      el: "", // 指定挂载的容器
      audioUrl: "https://cooltv-proxy.isus.cc/", // 音乐文件 URL
      position: "top:10px;right:10px", // 播放器位置
    });
  }
};
