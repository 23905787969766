<template>
  <div class="manage-user-container">
    <!-- 查询用户、编辑用户 -->
    <div class="dialog-container">
      <el-dialog :title="optDialog === 'query'? '查询用户' : '编辑用户'" :visible="optDialog === 'query' || optDialog === 'edit'" width="30%" :before-close="closeOptDialog"
        :close-on-click-modal="false">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px">
          <template v-if="optDialog === 'query'">
            <el-form-item label="查询条件" prop="query.condition">
              <el-select v-model="ruleForm.query.condition.selectedIndex" placeholder="请选择" @change="selectCondition">
                <el-option v-for="(item, index) in ruleForm.query.condition.options" :key="item.name" :label="item.text" :value="index"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="ruleForm.query.condition.selectedIndex !== ''? ruleForm.query.condition.options[ruleForm.query.condition.selectedIndex].text : ''" prop="query.value">
              <el-input v-if="ruleForm.query.condition.selectedIndex === 0 || ruleForm.query.condition.selectedIndex === 1" v-model="ruleForm.query.value" :placeholder="ruleForm.query.condition.placeholder"></el-input>
              <el-select v-if="ruleForm.query.condition.selectedIndex === 2" v-model="ruleForm.query.role.selectedName" placeholder="请选择" @change="selectRole">
                <el-option v-for="item in ruleForm.query.role.options" :key="item.name" :label="item.text"
                  :value="item.name"></el-option>
              </el-select>
              <el-select v-if="ruleForm.query.condition.selectedIndex === 3" v-model="ruleForm.query.isOnline.selectedValue" placeholder="请选择" @change="selectOnline">
                <el-option v-for="item in ruleForm.query.isOnline.options" :key="item.name" :label="item.text"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </template>
          <template v-if="optDialog === 'edit'">
            <el-form-item label="用户名">
              <el-input v-if="tableData.length > 0" v-model="tableData[index].username" disabled></el-input>
            </el-form-item>
            <el-form-item label="用户邮箱">
              <el-input v-if="tableData.length > 0" v-model="tableData[index].email" disabled></el-input>
            </el-form-item>
            <el-form-item label="用户角色" prop="edit.role">
              <el-select v-model="ruleForm.edit.role.selectedName" placeholder="请选择" @change="selectRole">
                <el-option v-for="item in ruleForm.edit.role.options" :key="item.name" :label="item.text"
                  :value="item.name"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否禁用">
              <el-switch v-model="ruleForm.edit.isBan" active-color="#13ce66" inactive-color="#ff4949"
                @change="switchStatus"></el-switch>
            </el-form-item>
          </template>
          <el-form-item>
            <el-button type="primary" @click="submitForm">提交</el-button>
            <el-button @click="resetForm">重置</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>

    <!-- 删除提示 -->
    <div class="dialog-container">
      <el-dialog title="提示" :visible="optDialog === 'delete'" width="30%" :before-close="closeOptDialog"
        :close-on-click-modal="false">
        <span v-if="tableData.length > 0">是否确认删除用户（{{ tableData[index].username }}）？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="optDialog = ''">我再想想</el-button>
          <el-button type="primary" @click="deleteConfirmed">确 定</el-button>
        </span>
      </el-dialog>
    </div>

    <!-- 表格容器 -->
    <div class="table-container">
      <!-- 操作容器 -->
      <div class="opt-container">
        <!-- 查询按钮 -->
        <el-button type="primary" @click="getUser">查询</el-button>
      </div>

      <!-- 表格内容 -->
      <el-table v-loading="loading" element-loading-text="加载中，请稍后..." :data="tableData" border>
        <!-- 表格列定义 -->
        <el-table-column prop="no" label="序号" width="50" header-align="center" align="center"
          :resizable=false></el-table-column>
        <el-table-column prop="username" label="用户名" width="150" header-align="center" align="center"
          :resizable=false></el-table-column>
        <el-table-column prop="email" label="邮箱" width="180" header-align="center" align="center"
          :resizable=false></el-table-column>
        <el-table-column prop="role.text" label="角色" width="120" header-align="center" align="center"
          :resizable=false></el-table-column>
        <el-table-column prop="status" label="状态" width="80" header-align="center" align="center" :resizable=false>
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.status.name === 'active' ? 'success' : scope.row.status.name === 'locked' ? 'warning' : 'danger'"
              effect="dark">{{ scope.row.status.text }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="isOnline" label="是否在线" width="80" header-align="center" align="center" :resizable=false>
          <template slot-scope="scope">
            {{ scope.row.isOnline === 1 ? '是' : '否' }}
          </template>
        </el-table-column>
        <el-table-column prop="lastLoginIp" label="上次登录IP" width="140" header-align="center" align="center"
          :resizable=false></el-table-column>
        <el-table-column prop="lastLoginTime" label="上次登录时间" width="160" header-align="center" align="center"
          :resizable=false></el-table-column>
        <el-table-column prop="registerTime" label="注册时间" width="160" header-align="center" align="center"
          :resizable=false></el-table-column>
        <el-table-column label="操作" header-align="center" min-width="200" align="center">
          <template slot-scope="scope">
            <el-button size="mini" @click="editUser(scope.$index, scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="deleteUser(scope.$index, scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页容器 -->
      <div class="pagination-container">
        <!-- 分页组件 -->
        <el-pagination @size-change="changeSize" @current-change="changePage" :current-page="page"
          :page-sizes="[10, 30, 50]" :page-size="pageSize" :total="total"
          layout="total, sizes, prev, pager, next, jumper"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import userService from '@/api/services/userService';
export default {
  name: 'ManageUser',
  data() {
    var validateValue = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('不能为空'));
      } else {
        callback();
      }
    };

    return {
      loading: false, // 控制表格的加载状态
      role: {
        name: 'guest',
        text: '游客',
        level: 1
      }, // 用户默认角色
      ruleForm: {
        query: {
          name: 'username',
          value: '',
          condition: {
            selectedIndex: 0,
            placeholder: '忽略大小写，支持模糊查询',
            options: [{
              name: 'username',
              text: '用户名',
              placeholder: '忽略大小写，支持模糊查询',
            }, {
              name: 'email',
              text: '邮箱',
              placeholder: '忽略大小写，支持模糊查询',
            }, {
              name: 'role',
              text: '角色',
            }, {
              name: 'is_online',
              text: '是否在线',
            }]
          },
          role: {
            selectedName: 'premium',
            options: [{
              name: 'admin',
              text: '超级管理员',
            }, {
              name: 'manager',
              text: '普通管理员',
            }, {
              name: 'premium',
              text: '会员用户', 
            }, {
              name: 'user',
              text: '普通用户',
            }]
          },
          isOnline: {
            selectedValue: 1,
            options: [{
              value: 0,
              text: '离线用户',
            }, {
              value: 1,
              text: '在线用户',
            }]
          },
        },
        edit: {
          role: {
            selectedName: 'user',
            options: [{
              name: 'admin',
              text: '超级管理员',
            }, {
              name: 'manager',
              text: '普通管理员',
            }, {
              name: 'plus',
              text: '高级用户', 
            }, {
              name: 'premium',
              text: '会员用户', 
            }, {
              name: 'user',
              text: '普通用户',
            }]
          },
          isBan: false,
        }
      },
      rules: {
        'query.condition': [
          { required: true, trigger: 'blur' }
        ],
        'query.value': [
          { required: true, validator: validateValue, trigger: 'blur' }
        ],
        'edit.role': [
          { required: true, trigger: 'blur' }
        ],
      },
      popoverVisible: false,
      optDialog: '',
      managePassword: '',
      index: 0,
      // 表格初始数据
      tableData: [
      ],
      // 表格当前页码
      page: 1,
      // 表格页大小
      pageSize: 10,
      // 表格总记录
      total: 0,
    };
  },
  mounted() {
    // 初始化时加载数据
    this.getData();

    // 读取角色信息，若不存在则使用默认角色
    this.role = JSON.parse(localStorage.getItem('role')) || { name: 'guest', text: '游客', level: 1 };
  },
  methods: {
    // 获取数据
    getData() {
      // 表格加载中
      this.loading = true;

      // 准备请求参数
      const params = {
        page: this.page,         // 当前页码
        page_size: this.pageSize // 页面大小
      };

      // 如果查询字段名不为空，则将其添加到请求参数中
      if (this.ruleForm.query.value !== '') {
        params.query = {
          name: this.ruleForm.query.name,
          value: this.ruleForm.query.value,
        };
      }

      // 通过 userService 获取用户列表信息
      userService.listUsers(params).then(response => {
        this.total = response.data.total; // 设置总记录数为服务器响应中返回的总记录数
        // 构建表格数据
        this.tableData = response.data.list.map((item, index) => {
          // 返回表格数据对象
          return {
            no: (index + 1), // 行序号，基于当前项的索引加1
            id: item.id, // 用户ID
            username: item.username, // 用户名
            email: item.email, // 用户邮箱
            role: {
              name: item.role_name, // 用户角色名称
              text: item.role_text, // 用户角色描述
            },
            status: {
              name: item.status_name, // 用户状态名称
              text: item.status_text, // 用户状态描述
            },
            isOnline: item.is_online, // 用户是否在线
            lastLoginIp: item.last_login_ip, // 用户最后登录时间
            lastLoginTime: item.last_login_time, // 用户最后登录时间
            registerTime: item.register_time, // 用户注册时间
          };
        });
      })
      .catch(error => {
        console.error(error); // 捕获并输出错误
      })
      .finally(() => {
        // 表格加载完毕
        this.loading = false;
      });
    },
    // 当每页条数变化时
    changeSize(pageSize) {
      this.page = 1;
      this.pageSize = pageSize;
      this.getData();
    },
    // 当前页码变化时
    changePage(page) {
      this.page = page;
      this.getData();
    },
    selectCondition(index) {
      this.ruleForm.query.value = '';

      const option = this.ruleForm.query.condition.options[index];
      this.ruleForm.query.condition.selectedIndex = index;

      // 清除所有字段的验证状态
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate();
      });

      if (index === 0 || index === 1) {
        this.ruleForm.query.condition.placeholder = option['placeholder'];
      } else if (index === 2) {
        this.ruleForm.query.role.selectedName = 'premium';
        this.ruleForm.query.value = 'premium';
      } else if (index === 3) {
        this.ruleForm.query.isOnline.selectedValue = 1;
        this.ruleForm.query.value = 1;
      }
      this.ruleForm.query.name = this.ruleForm.query.condition.options[index].name;
    },
    selectOnline(value) {
      this.ruleForm.query.isOnline.selectedValue = value;
      this.ruleForm.query.value = value;
    },
    selectRole(name) {
      if (this.optDialog === 'query') {
        this.ruleForm.query.role.selectedName = name;
        this.ruleForm.query.value = name;
      } else if (this.optDialog === 'edit') {
        this.ruleForm.edit.role.selectedName = name;
      }
    },
    switchStatus(isBan) {
      this.ruleForm.edit.isBan = isBan;
    },
    closeOptDialog() {
      // 关闭窗口
      this.optDialog = '';
    },
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.optDialog === 'query') {
            // 初始化当前页码
            this.page = 1;
            // 获取数据
            this.getData();
            // 关闭窗口
            this.optDialog = '';
            // 重置对话框数据
            this.resetForm();
          } else if (this.optDialog === 'edit') {
            // 准备请求参数
            const params = {
              id: this.tableData[this.index].id,
              role: this.ruleForm.edit.role.selectedName,
              is_ban: this.ruleForm.edit.isBan ? 1 : 0,
            };
            userService.editUser(params).then(response => {
              // 获取数据
              this.getData();
              // 输出成功提示
              this.$message.success(response.message);
            })
            .catch(error => {
              console.error(error); // 捕获并输出错误
            })
            .finally(() => {
              // 关闭窗口
              this.optDialog = '';
              // 重置对话框数据
              this.resetForm();
            });
          }
        } else {
          this.$message.error('请检查表单数据！');
          return false;
        }
      });
    },
    resetForm() {
      // 清除所有字段的验证状态
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate();
      });

      if (this.optDialog === 'query') {
        // 重置数据
        this.ruleForm.query.name = 'username';
        this.ruleForm.query.value = '';
        this.ruleForm.query.condition.selectedIndex = 0;
        this.ruleForm.query.condition.placeholder = '忽略大小写，支持模糊查询';
        this.ruleForm.query.role.selectedName = 'premium';
        this.ruleForm.query.isOnline.selectedValue = 1;
      } else if (this.optDialog === 'edit') {
        // 重置数据
        this.ruleForm.edit.role.selectedName = 'user';
        this.ruleForm.edit.isBan = false;
      }
    },
    getUser() {
      // 打开查询对话框
      this.optDialog = 'query';
      // 重置数据
      this.resetForm();
    },
    editUser(index, row) {
      // 记录当前编辑任务的索引
      this.index = index;

      // 打开编辑对话框
      this.optDialog = 'edit';

      // 重置数据
      this.resetForm();

      this.ruleForm.edit.role.selectedName = row.role.name;
      this.ruleForm.edit.isBan = row.status.name === 'suspended' ? true : false;
    },
    deleteUser(index, row) {
      this.index = index;
      this.optDialog = 'delete';
    },
    deleteConfirmed() {
      // 准备请求参数
      const params = {
        id: this.tableData[this.index].id
      };
      userService.deleteUser(params).then(response => {
        // 获取数据
        this.getData();
        // 输出成功提示
        this.$message.success(response.message);
      })
        .catch(error => {
          console.error(error); // 捕获并输出错误
        })
        .finally(() => {
          // 关闭窗口
          this.optDialog = '';
          // 重置操作项的索引
          this.index = 0;
        });
    },
  },
}
</script>

<style scoped lang="scss">
.manage-user-container ::v-deep {
  .dialog-container {
    .el-dialog__wrapper {
      .el-form-item__content {

        .el-select,
        .el-cascader,
        .el-checkbox-group {
          width: 100%;
        }

        .el-tag+.el-tag {
          margin-left: 10px;
        }

        .button-new-tag {
          margin-left: 10px;
          height: 32px;
          line-height: 30px;
          padding-top: 0;
          padding-bottom: 0;
        }

        .input-new-tag {
          width: 90px;
          margin-left: 10px;
          vertical-align: bottom;
        }

      }
    }
  }

  /* 移动端样式（小于 767px） */
  @media screen and (max-width: 767px) {
    .dialog-container {
      .el-dialog {
        width: 90% !important;
      }
    }

    /* 表格最大高度 */
    .el-table {
      max-height: 500px;
    }

    /* 分页组件横向滚动样式 */
    .pagination-container {
      overflow-x: scroll;
    }
  }

  /* PC端样式（大于等于 768px） */
  @media screen and (min-width: 768px) {

    /* 表格最大高度 */
    .el-table {
      max-height: 380px;
    }
  }

  /* 表格相关样式 */
  .table-container {
    padding: 0 20px;

    /* 操作组件样式 */
    .opt-container {
      margin: 10px 0;
      text-align: right;
    }

    /* 分页组件样式 */
    .pagination-container {
      margin: 30px 0;
    }

    /* 表格内整体样式 */
    .el-table {
      position: relative;
      overflow-y: auto;
      /* 表格外边框颜色 */
      border: 2px solid #4682B4;

      /* 去掉表格底部多余的白色边框 */
      &::before {
        height: 0;
      }

      /* 设置表格遮罩为全屏 */
      .el-loading-mask {
        position: fixed;
      }

      /* 表格头部样式 */
      .el-table__header-wrapper {
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: #FFFFFFAA;
      }

      /* 表头单元格边框颜色 */
      .el-table__header-wrapper thead th {
        border-right: 2px solid #4682B4 !important;
        border-bottom: 2px solid #4682B4 !important;
      }

      /* 去除表头最后一个单元格右边框颜色 */
      .el-table__header-wrapper thead th:nth-last-child(2) {
        border-right: none !important;
      }

      /* 内部单元格边框颜色 */
      .el-table__body-wrapper tbody tr td,
      .el-table__fixed tbody tr td {
        border-right: 2px solid #4682B4 !important;
        border-bottom: 2px solid #4682B4 !important;
      }

      /* 去除内部单元格最后一个单元格右边框颜色 */
      .el-table__body-wrapper tbody tr td:nth-last-child(1),
      .el-table__fixed tbody tr td:nth-last-child(1) {
        border-right: none !important;
      }

      /* 去除最后一行内部单元格下边框颜色 */
      .el-table__body-wrapper tbody tr:nth-last-child(1) td,
      .el-table__fixed tbody tr:nth-last-child(1) td {
        border-bottom: none !important;
      }

      /* 表格内背景颜色透明 */
      th.el-table__cell,
      tr,
      td {
        background-color: transparent;
      }
    }

    .el-table--border {

      /* 去掉表格右侧多余的白色边框 */
      &::after {
        width: 0;
      }
    }

    /* 最外层透明 */
    .el-table,
    .el-table__expanded-cell {
      background-color: transparent;
    }
  }
}
</style>
