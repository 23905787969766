import Vue from 'vue';
import axios from 'axios';
import { API_URLS } from './apiUrls';
import { MessageBox } from 'element-ui';
import router from '@/router';
import userService from '@/api/services/userService';

// 创建axios实例
const apiInstance = axios.create({
  baseURL: process.env.NODE_ENV === 'production'? process.env.VUE_APP_API_URL + '/v1' : 'http://localhost:8080/api',
  timeout: 3 * 1000, // 默认请求超时时间
});

// 请求拦截器
apiInstance.interceptors.request.use(
  async config => {
    if (config.headers.requiresAuth) {
      const token = localStorage.getItem('token'); // 从浏览器中获取存储的 token
      const expiresTime = localStorage.getItem('expires_time'); // 获取 token 的过期时间

      // 判断是否需要携带 token
      if (token && expiresTime) {
        const currentTime = Math.floor(Date.now() / 1000); // 当前时间戳，单位是秒
        const bufferTime = 1; // 设置一个缓冲时间，单位是秒
        if (currentTime + bufferTime < expiresTime) {
          // 如果当前时间小于过期时间，则 token 未过期，可以携带到请求头中
          config.headers.Authorization = `Bearer ${token}`;
        } else {
          try {
            // 如果当前时间大于等于过期时间，则 token 已过期，需要刷新 token
            await userService.refreshToken(); // 调用刷新 token
            const token = localStorage.getItem('token'); // 从浏览器中获取存储的 token
            config.headers.Authorization = `Bearer ${token}`;
          } catch (error) {
            // 请求刷新token失败，跳转到登录页
            router.push({ path: '/auth/login', query: { redirect: router.currentRoute.fullPath } });
            // 中止请求链
            return;
          }
        }
      } else {
        // 询问用户是否要登录
        MessageBox.confirm('请登录以后再继续操作！', '提示', {
          confirmButtonText: '去登录',
          cancelButtonText: '返回',
          type: 'warning',
          closeOnClickModal: false, // 设置不能点遮罩关闭
        }).then(() => {
          // 无 token，跳转到登录页
          router.push({ path: '/auth/login', query: { redirect: router.currentRoute.fullPath } });
        }).catch(() => {
          // 返回上一级
          router.go(-1);
        });
        // 中止请求链
        return;
      }
    }

    // 清除自定义的标记 header，避免发送到服务器
    delete config.headers.requiresAuth;

    return config;
  },
  error => {
     // 处理请求错误
    return Promise.reject(error);
  }
);

// 响应拦截器
apiInstance.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    // 当响应消息为access_token不存在或已过期时，跳转到登录页
    if (error.response && error.response.data.message === 'access_token不存在或已过期') {
      router.push({ path: '/auth/login', query: { redirect: router.currentRoute.fullPath } });
      // 中止响应链
      return Promise.reject(error);
    }

    // 提示用户，网络请求超时
    if (error.message.includes('timeout')) {
      Vue.prototype.$message({
        message: '网络请求超时',
        type: 'error',
        duration: 1000
      });
      // 中止响应链
      return Promise.reject(error);
    }

    // 仅当 quiet 被明确设置为 true 时才抑制错误消息
    const isQuiet = !!(error.config && error.config.quiet === true);
    if (!isQuiet && error.response) {
      const errorMessage = (error.response.data.data && error.response.data.data.info) || error.response.data.message || '发生未知错误';
      Vue.prototype.$message({
        message: errorMessage,
        type: 'error',
        duration: error.config.errorMessageDuration || 3000
      });
    }
    return Promise.reject(error);
  }
);

export { apiInstance, API_URLS };