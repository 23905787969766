<template>
  <div class="user-settings-container">
    <div class="settings-form">
      <el-form ref="settingsForm" :model="settings" label-width="80px" label-position="left" disabled>
        <el-form-item label="用户名">
          <el-input v-model="settings.username"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" class="letter-spacing">
          <el-input v-model="settings.email"></el-input>
        </el-form-item>
        <el-form-item label="身份" class="letter-spacing">
          <el-input v-model="settings.role"></el-input>
        </el-form-item>
      </el-form>
      <el-button v-if="role.name === 'guest'" type="primary" @click="toLogin">登&emsp;录</el-button>
      <el-button v-else type="danger" @click="logout">退&emsp;出</el-button>
    </div>
  </div>
</template>

<script>
import userService from '@/api/services/userService';
export default {
  name: 'UserSettings',
  data() {
    return {
      role: {
        name: 'guest',
        text: '游客',
        level: 1
      }, // 用户默认角色
      settings: {
        username: '',
        email: '',
        role: '',
      }
    };
  },
  mounted() {
    // 读取角色信息，若不存在则使用默认角色
    this.role = JSON.parse(localStorage.getItem('role')) || { name: 'guest', text: '游客', level: 1 };
    // 判断是否是游客
    if (this.role.name === 'guest') {
      this.settings.role = this.role.text;
    } else {
      // 初始化时加载数据
      this.getData();
    }
  },
  activated() {
    this.getData();
  },
  methods: {
    // 获取数据
    getData() {
      // 通过 userService 获取用户信息
      userService.getUserInfo().then(response => {
        // 从响应中获取必要的信息
        this.settings.username = response.data.username;
        this.settings.email = response.data.email;
        this.settings.role = response.data.role_text;
      })
      .catch(error => {
        console.error(error); // 捕获并输出错误
      });
    },
    // 路由到登录页面
    toLogin() {
      this.$router.push('/auth/login');
    },
    logout() {
      // 调用退出登录方法
      userService.logout().then(response => {
        // 清除所有本地存储数据
        localStorage.clear();
        // 退出登录后的跳转
        this.$router.push('/auth/login');
      })
      .catch(error => {
        console.error(error);
      });
    }
  }
}
</script>

<style scoped lang="scss">
.user-settings-container ::v-deep {
  width: 300px;
  margin: 0 auto;
  padding: 20px;
  .settings-form {
    .el-form-item {
      margin-bottom: 20px;

      .el-input {
        width: 100%;
      }
    }
    .letter-spacing {
      .el-form-item__label {
        letter-spacing: 1em;
      }
    }

    .el-button {
      width: 100%;
      margin-top: 20px;
    }
  }
}
</style>
