<template>
  <div class="share-container">
    <div class="share-list-container">
      <div class="share-item" v-for="resource in resources" :key="resource.id">
        <a :href="resource.url" target="_blank">
          <div class="logo"><img :src="resource.logo" /></div>
          <div class="name">{{ resource.name }}</div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Share',
  data() {
    return {
      resources: [
        { id: 1, logo: require('@/assets/img/resource_download/logo.png'), name: '台标', url: 'https://qwtc.cc/share/' },
        { id: 2, logo: require('@/assets/img/resource_download/software.png'), name: '软件', url: 'http://synology.isus.cc:2892/' },
        { id: 3, logo: require('@/assets/img/resource_download/picture.png'), name: '图库', url: 'http://synology.isus.cc:616/' },
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.share-container {
  padding: 20px;

  .share-list-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    .share-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
      padding: 10px;
      transition: transform 0.3s;

      &:hover {
        transform: scale(1.05);
      }

      a {
        text-decoration: none;
        color: inherit;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .logo {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: hidden;
        border: 2px solid #87CEEB;

        img {
          width: 100%;
          height: auto;
        }
      }

      .name {
        margin-top: 10px;
        font-weight: bold;
      }
    }
  }

  /* PC端样式（大于等于 768px） */
  @media screen and (min-width: 768px) {
    /* 容器宽度 */
    .share-list-container {
      width: 1000px;
      margin: 0 auto;
      margin-top: 150px;
    }
  }

  /* 移动端样式（小于 767px） */
  @media screen and (max-width: 767px) {
    .share-list-container {
      margin-top: 50px;
      .share-item {
        margin-right: 0;
        width: 45%;
        margin-bottom: 20px;
      }
    }
  }
}
</style>
